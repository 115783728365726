import colors from "@constants/colors";
import { handleMinimize, handleRemoveToolBox } from "@redux/slice/live-slice";
import { useDispatch } from "react-redux";

const ToolBoxItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(handleMinimize(null));
    setTimeout(() => {
      dispatch(handleRemoveToolBox(item));
    }, [200]);
  };
  return (
    <div
      className="flex items-center justify-center"
      style={{
        lineHeight: "40px",
        cursor: "pointer",
        width: "max-content",
        height: "40px",
        padding: "10px",
        margin: "5px",
        border: "1px solid black",
        backgroundColor: "#363535",
        color: colors.textBase,
      }}
      onClick={handleClick}
    >
      {`Kênh ${item.i}`}
    </div>
  );
};

export default ToolBoxItem;
