import HlsPlayer from "@components/live/hls-player";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const CustomVideoSetting = ({ url, id,controls,reset}) => {
  const dispatch = useDispatch();
  const [urls, setUrls] = useState();
  const videoRef = useRef(null);

  useEffect(() => {
    startStream();
  }, [url]);

  const stopStream = (url) => {
    if (url) {
      const callBack = (data) => { 
      };
      dispatch({
        type: TYPE_ACTION.LIVE.STOP_STREAM,
        payload: { id: url, callBack },
      });
    }
  };
  useEffect(() => {
    stopStream(urls);
  }, [reset]);

  const startStream = () => {
    setUrls()
    const callBack = (data) => {
      if (data) {
        setUrls(`${data[0].urlRestream}`);
      }
    };
    const errorBack = (data) => {

    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
      payload: { url: url, callBack, errorBack },
    });
  };
  return (
    <>
      {
        <div className="w-[56.25vw] flex justify-center items-center"
          style={{ background: colors.background}}
        >
            <HlsPlayer
            playerRef={videoRef}
            playingSrc={urls}
            idCamera={id}
            controls={controls}
            settingAi
          />
        </div>
      }
    </>
  );
};
export default CustomVideoSetting;
