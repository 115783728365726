import { SERVICE_PATHS } from "@constants/url";
import { get, post } from "src/api/api-map";

const LiveService = {
  getDatalistProvince: (body) => {
    if (body) {
      return get(`${SERVICE_PATHS.LIVE.GET_PROVINCE}?ids=${body}`);
    } else {
      return get(SERVICE_PATHS.LIVE.GET_PROVINCE);
    }
  },

  getCameraDataWithProvince: (body) => {
    var filterString = "?";
    Object.keys(body).forEach((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE);
    } else {
      return get(
        `${SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE}${filterString}`
      );
    }
  },
  getPreviewCamWithID: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_PREVIEW_WITH_ID}?ids=${body}`);
  },
  getDistric: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_DISTRICT}?province_ids=${body}`);
  },
  getWard: (body) => {
    return get(
      `${SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE}?district_ids=${body}&status=true`
    );
  },
  getDevice: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_DEVICE}?ward_ids=${body}`);
  },
  getAllCameraStream: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_ALL_CAMERA_STREAM}`);
  },
  stopStream: (id) => {
    return get(`${SERVICE_PATHS.LIVE.STOP_STREAM}?ids=${id}`);
  },
  stopAllStream: (id) => {
    return get(`${SERVICE_PATHS.LIVE.STOP_ALL_STREAM}`);
  },
  getGroup: (body) => {
    return get(SERVICE_PATHS.LIVE.GET_LIVE_GROUP);
  },
  getCameraNonAddress: (body) => {
    return get(SERVICE_PATHS.LIVE.GET_CAMERA_NON_ADDRESS);
  },
  createGroup: (body) => post(SERVICE_PATHS.LIVE.CREATE_GROUP, body),
  createCamera: (body) => post(SERVICE_PATHS.LIVE.CREATE_CAMERA, body),
  deleteCamera: (body) => post(SERVICE_PATHS.LIVE.DELETE_CAMERA, body),

  getDataCameraSearch: (body) => {
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index] !== null) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE);
    } else {
      return get(
        `${SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE}${filterString}&status=true`
      );
    }
  },
};

export default LiveService;
