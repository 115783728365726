import { Marker, Polygon } from "react-leaflet";
import L from "leaflet";
import colors from "@constants/colors";
const dataGeo = [
    {
        "lat": 17.23000493050636,
        "lng": 111.26953125000001
    },
    {
        "lat": 17.271973354308802,
        "lng": 111.32995605468751
    },
    {
        "lat": 17.3034434020238,
        "lng": 111.41235351562501
    },
    {
        "lat": 17.312621152161245,
        "lng": 111.44737243652345
    },
    {
        "lat": 17.317209855410525,
        "lng": 111.4720916748047
    },
    {
        "lat": 17.323109448362278,
        "lng": 111.48857116699219
    },
    {
        "lat": 17.327042405074096,
        "lng": 111.50985717773438
    },
    {
        "lat": 17.325731428860937,
        "lng": 111.54418945312501
    },
    {
        "lat": 17.324420443290116,
        "lng": 111.5654754638672
    },
    {
        "lat": 17.32114293842747,
        "lng": 111.57920837402345
    },
    {
        "lat": 17.318520892437274,
        "lng": 111.59294128417969
    },
    {
        "lat": 17.31589880903014,
        "lng": 111.60667419433595
    },
    {
        "lat": 17.315243282332556,
        "lng": 111.62178039550783
    },
    {
        "lat": 17.305410101331592,
        "lng": 111.67053222656251
    },
    {
        "lat": 17.24639998211939,
        "lng": 112.01934814453125
    },
    {
        "lat": 17.195242610429894,
        "lng": 112.32490539550783
    },
    {
        "lat": 17.175562931398197,
        "lng": 112.37434387207033
    },
    {
        "lat": 17.046281225389077,
        "lng": 112.57209777832033
    },
    {
        "lat": 16.822945238563165,
        "lng": 112.90924072265625
    },
    {
        "lat": 16.732221741160135,
        "lng": 112.96554565429689
    },
    {
        "lat": 16.65461230098613,
        "lng": 112.97515869140626
    },
    {
        "lat": 15.967930708968826,
        "lng": 112.79388427734376
    },
    {
        "lat": 15.901904654626879,
        "lng": 112.73895263671875
    },
    {
        "lat": 15.849728736257862,
        "lng": 112.65037536621095
    },
    {
        "lat": 15.82528727166032,
        "lng": 112.58926391601562
    },
    {
        "lat": 15.7750739159386,
        "lng": 112.34138488769533
    },
    {
        "lat": 15.763179456408846,
        "lng": 112.2905731201172
    },
    {
        "lat": 15.723526226324188,
        "lng": 112.30567932128908
    },
    {
        "lat": 15.671965477072874,
        "lng": 112.2466278076172
    },
    {
        "lat": 15.67989874853036,
        "lng": 112.18002319335939
    },
    {
        "lat": 15.708984771508783,
        "lng": 112.1697235107422
    },
    {
        "lat": 15.731457491108594,
        "lng": 112.15942382812501
    },
    {
        "lat": 15.737405736763273,
        "lng": 112.13813781738283
    },
    {
        "lat": 15.679568201707967,
        "lng": 111.81163787841798
    },
    {
        "lat": 15.564836205125268,
        "lng": 111.17958068847658
    },
    {
        "lat": 15.599890831526588,
        "lng": 111.07452392578126
    },
    {
        "lat": 15.667998725762747,
        "lng": 111.00379943847658
    },
    {
        "lat": 15.73806664220694,
        "lng": 110.96740722656251
    },
    {
        "lat": 15.781681647639433,
        "lng": 110.95779418945312
    },
    {
        "lat": 15.847747106092061,
        "lng": 110.96191406250001
    },
    {
        "lat": 15.892659274817108,
        "lng": 110.97702026367188
    },
    {
        "lat": 15.93888192165063,
        "lng": 110.99349975585939
    },
    {
        "lat": 15.98905445360722,
        "lng": 111.00448608398439
    },
    {
        "lat": 16.324093282434635,
        "lng": 111.06903076171876
    },
    {
        "lat": 17.09879223767869,
        "lng": 111.22558593750001
    },
    {
        "lat": 17.09747964258208,
        "lng": 111.22421264648439
    },
    {
        "lat": 17.23000493050636,
        "lng": 111.26815795898439
    },
    {
        "lat": 17.23000493050636,
        "lng": 111.26953125000001
    },
]

var icon = L.divIcon({
    className: 'custom-div-icon',
    html: `<h3 style="color: black;">
        Q.Đ Hoàng Sa
    </h3>`,
    iconSize: [30, 42],
    iconAnchor: [0, 42]
});

const HoangSaMap = ({ zoomShow}) => {
    const reversedArr = dataGeo.map(item => [item.lat, item.lng]);;
    return (
        <>
            <Polygon
                pathOptions={{
                    color: colors.textBase,
                    fillColor: "#A2CDD9",
                    fillOpacity: 1,
                    weight: 2,

                }}
                positions={
                    reversedArr
                }
            >   
            {
                <Marker
                    position={[15.7776774770001, 111.206695674]}
                    icon={icon}
                ></Marker>
            }
                
            </Polygon>
        </>
    )
}
export default HoangSaMap