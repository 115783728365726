import colors from "@constants/colors";
import { Checkbox, ConfigProvider, Form } from "antd";

const CustomCheckBox = ({
  title,
  checked = false,
  font = "font-semibold",
  widthText = "350px",
  isChecked = false,
  colorText = colors.textBase,
  onChange,
  name,
  defaultChecked
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.redGlobal,
          colorPrimaryHover: colors.redGlobal,
          colorPrimaryBorder: colors.redGlobal,
          colorPrimaryActive: colors.redGlobal,
          borderRadius: 0,
          colorBgBase: colors.background,
          colorText: colors.textBase,
          colorBorder: colors.textBase,
          controlInteractiveSize: 24,
        },
        components: {
          Checkbox: {
            checkedBg: colors.background_header,
            checkedColor: colors.textBase,
          },
          CheckboxGroup: {
            checkedBg: colors.background_header,
            checkedColor: colors.textBase,
          },
        },
      }}
    >
      {isChecked ? (
        <Form.Item name={name} valuePropName="checked" > 
          <Checkbox onChange={onChange} defaultChecked={defaultChecked} >
            <div style={{ width: widthText }}>
              <span className={`ml-[5px] ${font}`} style={{ color: colorText }}>
                {title}
              </span>
            </div>
          </Checkbox>
        </Form.Item>
      ) : (
        <div className="flex py-2">
          <div className={`flex my-[10px]`} style={{ width: widthText }}>
            <span
              className={`flex items-center justify-center ${font}`}
              style={{ color: colorText }}
            >
              {title}
            </span>
          </div>
          <Form.Item name={name}>
            <Checkbox onChange={onChange} />
          </Form.Item>
        </div>
      )}
    </ConfigProvider>
  );
};

export default CustomCheckBox;
