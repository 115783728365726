import { useEffect, useMemo, useState } from 'react';
import { ColorPicker } from 'antd';
const CustomColor = ({onChange,colors}) => {
  const [color, setColor] = useState(`#1677ff65`);
  const bgColor = useMemo(() => (typeof color === 'string' ? color : color.toHexString()), [color]);
  useEffect(()=>{
    if(colors){
      setColor(colors)
    }
  },[colors])
  return (
    <ColorPicker value={color} onChange={(e,p)=>{
        onChange(e,p)
        setColor(e,p)
    }}>
      <div className='flex space-x-3 items-center cursor-pointer w-max'>
        <div style={{
            height:"20px",
            width:"20px",
            background:bgColor
        }}></div>
        <p>Màu nền</p>
      </div>
    </ColorPicker>
  );
};
export default CustomColor;