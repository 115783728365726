/* eslint-disable array-callback-return */
import { useRef, useState, useEffect } from "react";
import { Stage, Layer } from "react-konva";
import { useSelector } from "react-redux";
import { pointPolygon } from "@redux/slice/live-slice";
import DrawPolygon from "./drawn-polygon";
import convexHull from "convex-hull";

export default function DrawnCanvas({ bgDraw, onData, idCamera, reset }) {
  const [points, setPoints] = useState([]);
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [position, setPosition] = useState([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [predictLine, setPredictLine] = useState([]);
  const [flatLinePts, setFlatLinePts] = useState();
  const [mdpts, setMdPts] = useState([]);
  const [isMouseOverMdpt, setMouseOverMdpt] = useState(false);
  const [isAddPts, setAddPts] = useState(false);
  const [isPolygon, setIsPolygon] = useState(false);
  const pointPolygons = useSelector(pointPolygon);
  const refCanvas = useRef(null);
  const viewportWidth = refCanvas.current?.clientWidth;
  const [size, setSize] = useState({
    width: viewportWidth,
    height: (viewportWidth * 9) / 16,
  });

  useEffect(() => {
    if (reset) {
      resetPts();
    } else {
      if (pointPolygons && pointPolygons.point) {
        if (pointPolygons.point?.length > 0) {
          setPolyComplete(true);
          setPoints(pointPolygons.point);
        }
      }
    }
  }, [pointPolygons, reset]);

  const sendDataToParent = (data) => {
    onData(points, bgDraw, idCamera);
  };

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  const IsConvexPolygon = (points) => {
    if (points.length >= 3) {
      const hull = convexHull(points);
      const isConvex = hull.length === points.length;
      return isConvex;
    }
    return true;
  };
  //handler when the mouse is clicked, adds point/midpt to array
  const handleMouseDown = (e) => {
    // as long as there is no right click and image is not draggable then add point as needed
    if (e.evt.button !== 2) {
      const stage = e.target.getStage();
      const mousePos = getMousePos(stage);

      //add midpoints after polygon is complete
      if (isPolyComplete && isAddPts && isMouseOverMdpt) {
        let ind = -1;

        //find the closest point from the midpoint array
        for (let i = 0; i < mdpts.length; ++i) {
          let p = mdpts[i];
          let xLow = p[0] - 15;
          let xHigh = p[0] + 15;
          let yLow = p[1] - 15;
          let yHigh = p[1] + 15;

          if (
            mousePos[0] >= xLow &&
            mousePos[0] <= xHigh &&
            mousePos[1] >= yLow &&
            mousePos[1] <= yHigh
          ) {
            ind = i;
          }
        }
        points.splice(ind + 1, 0, mdpts[ind]);
        setMdPts([]);
        setAddPts(false);
      }
      //don't add pts if polygon is complete and button not clicked
      else if (isPolyComplete) return;
      //finish the polygon if user clicks on the first point
      else if (
        isMouseOverPoint &&
        points.length >= 3 &&
        IsConvexPolygon(points)
      ) {
        setPolyComplete(true);
        setPredictLine([]);
        setIsPolygon(false);
      } else {
        if (IsConvexPolygon(points)) {
          setPoints([...points, mousePos]);
          setPredictLine([]);
          setIsPolygon(false);
        } else {
          setIsPolygon(true);
        }
      }
    } else {
      undo();
    }
  };
  const undo = () => {
    setPoints(points.slice(0, -1));
    setPolyComplete(false);
    setPredictLine([]);
  };

  //handler for when the mouse moves + used for the temp dotted line
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
    if (!isPolyComplete && points.length > 0 && IsConvexPolygon(points)) {
      setPredictLine([points[points.length - 1], mousePos]);
    }
  };

  //enlarger point when mouse is over midpoint, easier for user
  const handleMouseOnMdpt = (e) => {
    if (!isAddPts) return;
    e.target.scale({ x: 1.5, y: 1.5 });
    setMouseOverMdpt(true);
  };

  // keep point at regular size when mouse is not on top
  const handleMouseOffMdpt = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverMdpt(false);
  };

  // enlarge point when the mouse is over the start pt, easier for user
  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };

  // if the mouse if not on the start point, need to make every point
  //appear the same
  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };

  //when the user moves a point after polygon is complete
  const handlePointDragMove = (e) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    const newPoints = [
      ...points.slice(0, index),
      pos,
      ...points.slice(index + 1),
    ];
    if (IsConvexPolygon(newPoints)) {
      setPoints(newPoints);
      setIsPolygon(false);
    } else {
      e.target.position(e.target._lastPos);
      setIsPolygon(true);
    }
  };
  const handlePointDragEnd = (e) => {};

  //used for the original points
  useEffect(() => {
    setFlattenedPoints(
      points
        .concat(isPolyComplete ? [] : position)
        .reduce((a, b) => a.concat(b), [])
    );
    sendDataToParent();
    setFlatLinePts(predictLine.reduce((a, b) => a.concat(b), []));
  }, [points, isPolyComplete, position, predictLine]);

  //set new coordinates when user drags the polygon
  const handleGroupDragEnd = (e) => {
    if (e.target.name() === "polygon") {
      let result = [];
      let copyPoints = [...points];
      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      );
      e.target.position({ x: 0, y: 0 });
      setPoints(result);
    }
  };

  // to use for zooming rather then the button clicks
  const onScroll = (e) => {
    let np = e.deltaY;
    let scaleNP = e.deltaY * -0.2; // to prevent from zooming too much at once

    //zoom out
    if (np > 0) {
      if (size.width > 150 && size.height > 150) {
        for (let i = 0; i < points.length; i++) {
          let p = points[i];
          points[i] = [
            p[0] * (1 + scaleNP / size.width),
            p[1] * (1 + scaleNP / size.height),
          ];
        }
        size.width += scaleNP;
        size.height += scaleNP;
      }
    }

    //zoom in
    else if (np < 0) {
      for (let i = 0; i < points.length; i++) {
        let p = points[i];
        points[i] = [
          p[0] * (1 + scaleNP / size.width),
          p[1] * (1 + scaleNP / size.height),
        ];
      }

      size.width += scaleNP;
      size.height += scaleNP;
    }
  };

  const computeMdpts = () => {
    let mdArr = [];
    let x1;
    let y1;
    let x2;
    let y2;
    let p1;
    let p2;

    for (let i = 0; i < points.length; i++) {
      p1 = points[i];
      if (i === points.length - 1) {
        p2 = points[0];
      } else {
        p2 = points[i + 1];
      }

      x1 = p1[0];
      y1 = p1[1];
      x2 = p2[0];
      y2 = p2[1];

      let xVal = (x1 + x2) / 2;
      let yVal = (y1 + y2) / 2;
      let arr = [];
      arr.push(xVal, yVal);
      mdArr.push(arr);
    }

    return mdArr;
  };

  const addPt = () => {
    if (isPolyComplete) {
      setAddPts(true);
      setMdPts(computeMdpts());
    } else {
      setAddPts(false);
    }
  };

  const removeMdPts = () => {
    setMdPts([]);
  };

  const resetPts = () => {
    setPoints([]);
    setPolyComplete(false);
    setPredictLine([]);
    setAddPts(false);
    setMdPts([]);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        background: "transparent",
      }}
      className="space-x-10 w-[56.25vw]"
      ref={refCanvas}
    >
      <Stage
        width={size.width || 1080}
        height={size.height || (1080 * 9) / 16}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
      >
        <Layer>
          <DrawPolygon
            points={points}
            flattenedPoints={flattenedPoints}
            handlePointDragMove={handlePointDragMove}
            handleGroupDragEnd={handleGroupDragEnd}
            handleMouseOverStartPoint={handleMouseOverStartPoint}
            handleMouseOutStartPoint={handleMouseOutStartPoint}
            handleMouseOnMdpt={handleMouseOnMdpt}
            handleMouseOffMdpt={handleMouseOffMdpt}
            isFinished={isPolyComplete}
            handlePointDragEnd={handlePointDragEnd}
            mdPts={mdpts}
            bgDraw={bgDraw}
            predictLine={flatLinePts}
            sendPosition={(e) => {
              setPoints(e);
            }}
            IsConvexPolygon={IsConvexPolygon}
          />
        </Layer>
      </Stage>
      {isPolygon && (
        <div
          className="absolute font-semibold text-base"
          style={{ color: "red", bottom: 0 }}
        >
          Không được vẽ đa giác lõm, vui lòng bấm chuột phải để vẽ lại
        </div>
      )}
    </div>
  );
}
