import TYPE_ACTION from "@constants/action";
import {
  handleAddToolBox, handleMinimize,
  handleRotate, warningItemById
} from "@redux/slice/live-slice";
import { FaMinus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { RiFullscreenFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { AiOutlineRotateRight, AiFillAlert } from "react-icons/ai";
import { useRef } from "react";
import { GrPowerReset } from "react-icons/gr";

const HeaderVideo = ({
  idCamera,
  removeItemById,
  handleFullScreen,
  item,
  showAnimation,
  isFrame = false,
  handleReset,
}) => {
  const dispatch = useDispatch();
  const degCount = useRef(0)
  return (
    <div
      className="h-[10%] bg-[#000000a8] flex items-center justify-end p-[5px] absolute"
      style={{
        width: "100%",
        top: 0,
        zIndex: 999,
      }}
    >
      {isFrame ? (
        ""
      ) : (<>
        <GrPowerReset
          className="max-w-[40px] max-h-[40px] min-w-[20px] min-h-[20px]"
          style={{
            color: "#8d9db1",
            width: "auto",
            height: "90%",
            cursor: "pointer",
            padding: "0 5px",
          }}
          onClick={()=>handleReset(idCamera)}
        />
        <AiOutlineRotateRight
          className="max-w-[40px] max-h-[40px] min-w-[20px] min-h-[20px]"
          style={{
            color: "#8d9db1",
            width: "auto",
            height: "90%",
            cursor: "pointer",
            padding: "0 5px",
          }}
          onClick={() => {
            if(degCount.current<3){
              degCount.current++
            }
            else{
              degCount.current=0
            }
            dispatch(handleRotate({idCamera:idCamera, deg:degCount.current}));
          }}
        />
        <AiFillAlert
          className="max-w-[40px] max-h-[40px] min-w-[20px] min-h-[20px]"
          style={{
            color: "#8d9db1",
            width: "auto",
            height: "90%",
            cursor: "pointer",
            padding: "0 5px",
          }}
          onClick={() => {
            dispatch(warningItemById({idCamera:idCamera,isWarning:true}));
            setTimeout(() => {
            dispatch(warningItemById({idCamera:idCamera,isWarning:false}));
            }, 3000);
          }}
        />
      </>
      )}
      <RiFullscreenFill
        className="max-w-[40px] max-h-[40px] min-w-[20px] min-h-[20px]"
        style={{
          color: "#8d9db1",
          width: "auto",
          height: "80%",
          cursor: "pointer",
          padding: "0 5px",
        }}
        onClick={() => {
          handleFullScreen();
        }}
      />
      <FaMinus
        className="max-w-[40px] max-h-[40px] min-w-[20px] min-h-[20px]"
        style={{
          color: "#8d9db1",
          width: "auto",
          height: "80%",
          cursor: "pointer",
          padding: "0 5px",
        }}
        onClick={() => {
          dispatch(handleMinimize(idCamera));
          setTimeout(() => {
            dispatch(handleAddToolBox(item));
          }, [500]);
        }}
      />
      <IoClose
        className="max-w-[40px] max-h-[40px] min-w-[20px] min-h-[20px]"
        style={{
          color: "#8d9db1",
          width: "auto",
          height: "80%",
          cursor: "pointer",
        }}
        onClick={() => {
            removeItemById();
            const callBack = (data) => {};
            dispatch({
              type: TYPE_ACTION.LIVE.STOP_STREAM,
              payload: { id: idCamera, callBack },
            });
          }}
        />
    </div>
  );
};

export default HeaderVideo;
