import colors from "@constants/colors";
import { ConfigProvider, Popconfirm } from "antd";
import { useState } from "react";
import { RiArrowDownSFill, RiArrowRightSFill } from "react-icons/ri";
import { MdAddAPhoto } from "react-icons/md";
import { AiOutlineFolderAdd } from "react-icons/ai";

const TreeContent = ({
  title,
  content,
  icon,
  onCreateCamera,
  onCreateGroup,
  onSaveLayout,
}) => {
  const [isShow, setIsShow] = useState(false);
  const confirm = (e) => {
    console.log(e);
  };
  const cancel = (e) => {
    console.log(e);
  };
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: colors.background,
            borderRadius: 0,
          },
        }}
      >
        <Popconfirm
          title={null}
          description={
            <div className="w-full">
              <div
                className="flex items-center px-2 py-2 cursor-pointer text-white hover:bg-slate-500"
                onClick={onCreateCamera}
              >
                <MdAddAPhoto style={{marginRight:4, fontSize:22}} />
                <p>Tạo mới camera</p>
              </div>
              <div
                className="flex items-center px-2 pt-2 cursor-pointer text-white hover:bg-slate-500"
                onClick={onCreateGroup}
              >
                <AiOutlineFolderAdd style={{marginRight:4, fontSize:22}} />
                <p>Tạo mới Group</p>
              </div>
            </div>
          }
          onConfirm={confirm}
          icon={null}
          onCancel={cancel}
          trigger={["contextMenu"]}
          placement="bottomRight"
          okText="Yes"
          cancelText="No"
        >
          <div
            className="h-[35px] font-bold flex items-center"
            style={{
              borderBottom: "1px solid black",
            }}
          >
            {isShow ? (
              <RiArrowDownSFill
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => {
                  setIsShow(false);
                }}
              />
            ) : (
              <RiArrowRightSFill
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => {
                  setIsShow(true);
                }}
              />
            )}
            {icon}
            <span style={{ color: colors.textBase, paddingLeft: "5px",userSelect:"none" }}>
              {title}
            </span>
          </div>
        </Popconfirm>
        {isShow ? (
          <div className="overflow-auto scroll_default py-1">
            {content}
          </div>
        ) : null}
      </ConfigProvider>
    </>
  );
};

export default TreeContent;
