/* eslint-disable react-hooks/rules-of-hooks */
import CustomModal from "@common/modal/modal";
import SaveLayout from "@components/live/NX/content-modal-save-layout";
import SetLayout from "@components/live/NX/content-modal-set-layout";
import SettingAi from "@components/live/NX/content-modal-setting-ai";
import colors from "@constants/colors";
import { handleChangePointPolygon } from "@redux/slice/live-slice";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const items = [
  {
    key: "1",
    label: <span style={{color:colors.textBase}}>Lưu bố cục hiện tại</span>,
  },
  {
    key: "2",
    label: <span style={{color:colors.textBase}}>Thiết lập bố cục</span>,

  },
  {
    key: "3",
    label: <span style={{color:colors.textBase}}>Vẽ vùng AI</span>,

  },
  {
    key: "4",
    label: <span style={{color:colors.textBase}}>Tùy chọn bài toán</span>,

  },
  {
    key: "sub2",
    label: <span style={{color:colors.textBase}}>Cài đặt cảnh báo</span>,

    children: [
      {
        key: "5",
        label: <span style={{color:colors.textBase}}>cảnh báo người lạ</span>,

      },
      {
        key: "6",
        label: <span style={{color:colors.textBase}}>cảnh báo xâm nhập</span>,


      },
      {
        key: "sub3",
        label: <span style={{color:colors.textBase}}>Tùy chọn Loại cảnh báo</span>,

        children: [
          {
            key: "7",
            label: <span style={{color:colors.textBase}}>Mở toàn màn hình</span>,

          },
          {
            key: "8",
            label: <span style={{color:colors.textBase}}>Phát chuông cảnh báo</span>,

          },
        ],
      },
    ],
  },
];

const MenuRightClick = ({ x, y, isShowMenu }) => {
const [keyChange,setKeyChange]= useState(0)
const dispatch = useDispatch();
  const [openSaveLayout, setOpenSaveLayout] = useState(false);
  const [openSettingAI, setSettingAI] = useState(false);
  const [openSetLayout, setOpenSetLayout] = useState(false);
  const onClick = (e) => {
    switch (e.key) {
        case '1':
            setOpenSaveLayout(true);
            break;
        case '2':
            setOpenSetLayout(true);
            break;
        case '3':
          setSettingAI(true);
            break;
        default:
            break;
    }
  };
  useEffect(()=>{
    setKeyChange(pre=>pre+1)
  },[x,y])
  return (
    <div
      key={keyChange}
      className="absolute"
      style={{
        top: y,
        left: x,
        display: isShowMenu ? "flex" : "none",
        zIndex: 999,
      }}
    >
        <CustomModal
          content={
            <SaveLayout
              handleCancel={() => {
                setOpenSaveLayout(false);
              }}
            />
          }
          centered
          footer={false}
          width={"240px"}
          title={"Lưu bố cục"}
          open={openSaveLayout}
          handleCancel={() => {
            setOpenSaveLayout(false);
          }}
        />
        <CustomModal
          content={
            <SetLayout
              handleCancel={() => {
                setOpenSetLayout(false);
              }}
            />
          }
          centered
          footer={false}
          width={"240px"}
          title={"Thiết lập bố cục"}
          open={openSetLayout}
          handleCancel={() => {
            setOpenSetLayout(false);
          }}
        />
        <CustomModal
          content={
            <SettingAi
              handleCancel={() => {
                setSettingAI(false);
              }}
            />
          }
          footer={false}
          width={"1800px"}
          title={"Cài đặt kịch bản bài toán"}
          open={openSettingAI}
          handleCancel={() => {
            setSettingAI(false);
          }}
        />
      <Menu
        onClick={onClick}
        style={{
          width: 265,
        }}
        mode={"vertical"}
        items={items}
      />
    </div>
  );
};

export default MenuRightClick;
