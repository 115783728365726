import CustomButtonCommon from "@common/button/custom-button";
import CustomInput from "@common/input/custom-input";
import colors from "@constants/colors";
import {
  handleAddPolygon,
  handleDeletePolygon,
  handleSelectZoneAI,
  handleUpdatePolygon,
  pointPolygon,
  selectActiveCamera,
  selectPolygon,
} from "@redux/slice/live-slice";
import { Form } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomVideoSetting from "../view-video-setting";
import CustomColor from "@common/colorpicker/color-picker";
import RadioCheck from "@common/checkbox/custom-radio-check";
import DrawnCanvas from "../polygon/drawn-canvas";
import html2canvas from "html2canvas";
import { MdDeleteForever } from "react-icons/md";
import CustomCheckBox from "@common/checkbox/custom-check-box";

const options = [
  "Vùng bắt biển số xe",
  "Vùng đếm xe vào",
  "Vùng đếm xe ra",
  "Vùng xâm nhập",
];

const SettingAi = ({ handleCancel }) => {
  const listPolygon = useSelector(selectPolygon);
  const [bgDraw, setBgDraw] = useState("#1677ff65");
  const [isUpdate, setIsUpdate] = useState(false);
  const activeID = useSelector(selectActiveCamera);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [point, setPoint] = useState();
  const capture = useRef(null);
  const pointPolygons = useSelector(pointPolygon);
  const [keyChange, setKeyChange] = useState(0);
  const [reset, setReset] = useState(false);
  const [select, setSelect] = useState(0);

  const PolygonItem = ({
    item,
    select,
    colors,
    handleSelectZoneAI,
    dispatch,
    handleDeletePolygon,
  }) => {
    const { polygon } = item;
    const handleClick = () => {
      dispatch(handleSelectZoneAI(item));
      setIsUpdate(true);
      setReset(false);
      setInitialPolygon(item);
      setSelect(polygon.id);
    };

    const { id } = item.polygon;
    const handleDelete = (e) => {
      form.resetFields();
      setReset(true);
      setIsUpdate(false);
      setKeyChange((pre) => pre + 1);
      dispatch(handleDeletePolygon({ listPolygon, idPolygon: id }));
    };
    return (
      <div
        className={`flex flex-col justify-center items-center w-full py-3 cursor-pointer hover:bg-gray-700 ${
          select === id ? "bg-gray-700" : ""
        }`}
      >
        <div className="flex justify-between items-center w-[250px]">
          <div style={{ color: colors.textBase }} className="font-semibold">
            {item.nameZone}
          </div>
          {select === id && (
            <div className="cursor-pointer" onClick={handleDelete}>
              <MdDeleteForever size={25} />
            </div>
          )}
        </div>
        <img
          onClick={handleClick}
          className="px-2"
          src={item.imgs}
          style={{
            height: "auto",
            width: "250px",
          }}
          alt="#"
        />
      </div>
    );
  };

  useEffect(() => {
    setReset(true);
  }, []);

  const handleSetBgDraw = useCallback(() => {
    if (pointPolygons?.colors) {
      setBgDraw(pointPolygons.colors);
    }
  }, [pointPolygons]);

  useEffect(() => {
    handleSetBgDraw();
  }, [handleSetBgDraw]);

  const dispatch = useDispatch();
  const onFinish = () => {
    form.validateFields().then(async (values) => {
      html2canvas(capture.current).then((canvas) => {
        var image = canvas.toDataURL("image/png");
        if (values) {
          const { nameZone, zoneAI, showInVideo } = values;
          const payload = {
            nameZone,
            zoneAI,
            imgs: image,
            status: showInVideo,
            polygon: {
              id: isUpdate ? pointPolygons.id : listPolygon.length + 1,
              colors: bgDraw,
              idCamera: point.idCamera,
              point: point.point,
            },
          };
          if (isUpdate) {
            dispatch(handleUpdatePolygon({ payload, listPolygon }));
          } else {
            dispatch(handleAddPolygon(payload));
          }
        }
      });
      form.resetFields();
      setReset(true);
      setIsUpdate(false);
      setKeyChange((pre) => pre + 1);
    });
  };
  function setInitialPolygon(item) {
    form.setFieldsValue({
      nameZone: item.nameZone,
      zoneAI: item.zoneAI ? item.zoneAI : "Vùng bắt biển số xe",
      showInVideo: item.status,
    });
  }
  // const onFinishFailed = (errorInfo) => {};
  // const onChange = (e) => {};
  // const onChangeIsShowViewStream = (e) => {};
  const handleLoadPoint = (point, colors, idCamera) => {
    setPoint({
      point: point,
      colors: colors,
      idCamera: idCamera,
    });
  };
  //11996

  return (
    <Form
      form={form}
      ref={formRef}
      // onFinishFailed={onFinishFailed}
      onFinish={onFinish}
    >
      <div className="justify-between px-3 pt-3 border">
        <div className="flex h-max w-full">
          <div className="w-[20%] bg-slate-600 max-h-[608px] overflow-x-hidden scroll_default">
            <div
              style={{ background: colors.background }}
              className="p-1 font-bold"
            >
              Danh sách vùng
            </div>
            {listPolygon &&
              listPolygon.map((item, index) => {
                if (item.polygon.idCamera === activeID) {
                  return (
                    <PolygonItem
                      key={item.polygon.id}
                      item={item}
                      select={select}
                      colors={colors}
                      handleSelectZoneAI={handleSelectZoneAI}
                      dispatch={dispatch}
                      handleDeletePolygon={handleDeletePolygon}
                    />
                  );
                }
              })}
          </div>
          <div className="max-h-[700px] w-[1080px] scroll_default overflow-">
            <div className="w-max relative h-max" ref={capture}>
              <div className="absolute top-0">
                <CustomVideoSetting
                  id={activeID}
                  url={activeID}
                  reset={handleCancel}
                />
              </div>
              <DrawnCanvas
                key={keyChange}
                onData={(point, colors, idCamera) =>
                  handleLoadPoint(point, colors, idCamera)
                }
                idCamera={activeID}
                bgDraw={bgDraw}
                reset={reset}
              />
            </div>
          </div>
          <div className="bg-slate-600 max-h-[620px]">
            <div className="p-3">
              <CustomInput
                isVertical
                rules={[{ required: true, message: "Không được để trống!" }]}
                name={"nameZone"}
                title={"Tên vùng"}
                width="300px"
              />
            </div>
            <div className="p-3">
              <div style={{ background: colors.background }} className="p-1">
                Loại Vùng
              </div>
              <div className=" h-max p-3 bg-gray-700">
                <RadioCheck
                  name="zoneAI"
                  // onChange={onChange}
                  valueInit="Vùng bắt biển số xe"
                  isVertical={false}
                  options={options}
                />
              </div>
            </div>
            <div className="p-3">
              <div style={{ background: colors.background }} className="p-1">
                Tuỳ chỉnh màu zone
              </div>
              <div className="p-2 bg-gray-700">
                <CustomColor
                  colors={bgDraw}
                  onChange={(e, color) => {
                    setBgDraw(color);
                  }}
                />
              </div>
            </div>
            <div className="p-3">
              <div className="p-2 bg-gray-700">
                <CustomCheckBox
                  name="showInVideo"
                  isChecked
                  // onChange={onChangeIsShowViewStream}
                  title={"Hiển thị trên màn hình trực tiếp"}
                />
              </div>
            </div>
            <div className="w-full justify-center flex pt-5">
              <CustomButtonCommon
                text={isUpdate ? "Cập nhật" : "Lưu"}
                width="100px"
                backgroundColor={colors.redGlobal}
                htmlType={"submit"}
              />
              {isUpdate && (
                <div className="ml-2">
                  <CustomButtonCommon
                    onClick={() => {
                      setIsUpdate(false);
                      setReset(true);
                      form.resetFields();
                    }}
                    text={"Vẽ Polygon mới"}
                    width="max-content"
                    backgroundColor={colors.redGlobal}
                    htmlType={"submit"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end py-2 pr-2">
          <CustomButtonCommon
            text={"Hủy"}
            width="100px"
            onClick={(e) => {
              handleCancel();
              form.resetFields();
            }}
          />
        </div>
      </div>
    </Form>
  );
};

export default SettingAi;
