import { imagesGroup } from "@common/images/image";
import CustomMap from "@components/map/map-custom";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { selectToggle } from "@redux/slice/action-slice";
import { selectTotalCamAll } from "@redux/slice/map-slice";
import { ConfigProvider, Tooltip } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import "./custom-marker.css";
import CustomTreeLoad from "@common/tree/tree-loader";
import { handleDragItem, selectListProvince } from "@redux/slice/live-slice";
import { FaRegFolderOpen } from "react-icons/fa6";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";

const MapSingle = () => {
  const isShowDraw = useSelector(selectToggle);
  const nodeSelect = useRef(null);
  const total = useSelector(selectTotalCamAll);
  const dispatch = useDispatch();
  const dataListTree = useSelector(selectListProvince);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const mapRef = useRef(null);
  const [dataTree, setDataTree] = useState();
  const [hightlight, setHightlight] = useState();
  const [attribution, setAttribution] = useState(
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  );
  const [urlMap, setUrlMap] = useState(
    "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  const filterLocationCameraRef = useRef({
    iaddress_ids: null,
    district_ids: null,
    ids: null,
    ips: null,
    hasCamera: true,
    hasRecorder: true,
    names: null,
    province_ids: null,
    status: true,
    page_size: 10,
    page_index: 0,
  });
  const loadDataCame = () => {
    dispatch({
      type: TYPE_ACTION.MAP.GET_LOCATION_CAMERA,
      payload: filterLocationCameraRef.current,
    });
  };
  const getTotalCamera = () => {
    dispatch({
      type: TYPE_ACTION.MAP.GET_TOTAL,
      payload: {},
    });
  };
  const loadDataProvince = () => {
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_PROVINCE,
      payload: {},
    });
  };
  useEffect(() => {
    loadDataCame();
    getTotalCamera();
    loadDataProvince();
  }, []);

  useEffect(() => {
    mapData(dataListTree);
  }, [dataListTree]);

  const mapData = (data) => {
    const arr = [];
    data?.map((item, index) => {
      const key = item.id;
      if (item.id === 1) {
        // console.log(item)
      }
      const treeNode = {
        title: item.name,
        key,
        id: item.id,
        isLeaf: false,
        children: [],
        item: item,
        level: 0,
      };
      arr.push(treeNode);
    });
    setDataTree(arr);
  };
  const CameraSingle = ({ node, name, key }) => {
    return (
      <Tooltip placement="right" zIndex={1}>
        <div
          className="flex justify-center items-center"
          key={node.key}
          style={{ color: colors.text }}
        >
          <img
            style={{ marginRight: "5px" }}
            src={
              node.camera
                ? node.status
                  ? imagesGroup.camera_connected
                  : imagesGroup.camera_disconnected
                : null
            }
            alt=""
          />
          <span
            className="truncate max-w-[140px]"
            style={{
              color: `${
                node.show
                  ? node.status
                    ? colors.color_camera_connected
                    : colors.redGlobal
                  : colors.textPlaceholder
              }`,
            }}
          >
            {name}
          </span>
        </div>
      </Tooltip>
    );
  };
  const sendMessageToParent = (item) => {
    const message = { type: "MAP_EVENT", payload: item };
    window.parent.postMessage(message, "*");
  };
  const CameraGroup = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = { type: "group", id: node.id };
        sendMessageToParent(dragItem);
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
    return (
      <div
        ref={drag}
        key={node.key}
        className="truncate flex justify-center items-center"
        style={{
          color:
            nodeSelect.current === node.key
              ? colors.redGlobal
              : colors.textBase,
          fontWeight: nodeSelect.current === node.key ? "bold" : "normal",
        }}
      >
        <FaRegFolderOpen style={{ marginRight: "5px", fontSize: "22px" }} />
        <ConfigProvider
          theme={{
            token: {
              colorBgBase: colors.background,
              borderRadius: 0,
            },
          }}
        >
          <div className="truncate max-w-[180px]">{name}</div>
        </ConfigProvider>
      </div>
    );
  };

  const memoizedTitleRender = useMemo(() => {
    return (node) => {
      const hasChildren = node.children && node.children.length >= 0;
      if (hasChildren) {
        return <CameraGroup key={node.key} node={node} name={node.title} />;
      } else {
        return <CameraSingle key={node.key} node={node} name={node.title} />;
      }
    };
  }, []);
  const onSelect = (node, info) => {
    if (info.node.level === 0) {
      setHightlight(info.node.item.regionCode);
      mapRef.current.flyTo(
        [info.node.item.maxLatitude, info.node.item.minLongitude],
        7,
        {
          duration: 1,
        }
      );
      filterLocationCameraRef.current.province_ids = info.node.item.id;
      filterLocationCameraRef.current.page_size = 3000;
      loadDataCame();
    }
    nodeSelect.current = node[0];
  };
  const onExpand = (expandedKeys, { node }) => {
    if (node.level === 0) {
      setHightlight(node.item.regionCode);
      mapRef.current.flyTo([node.item.maxLatitude, node.item.minLongitude], 7, {
        duration: 1,
      });
      filterLocationCameraRef.current.province_ids = node.item.id;
      loadDataCame();
    }
    nodeSelect.current = node.key;
  };
  const [isShowTree, setIsShowTree] = useState(true);
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="target w-full flex flex-1 overflow-auto h-[100vh] relative">
        {isShowTree ? (
          <div
            className="max-h-[100vh] overflow-auto scroll_default w-[300px]"
            style={{ background: colors.backgroundSide }}
          >
            <CustomTreeLoad
              initTreeData={dataTree}
              onSelect={onSelect}
              data={dataListTree}
              titleRender={memoizedTitleRender}
              onExpand={onExpand}
            />
          </div>
        ) : (
          ""
        )}
        {isShowTree ? (
          <div
            className="h-[36px] rounded-[4px] flex justify-center items-center"
            style={{ background: colors.backgroundSide }}
          >
            <RiArrowLeftSFill
              style={{
                fontSize: "20px",
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => {
                setIsShowTree(false);
              }}
            />
          </div>
        ) : (
          <div
            className="h-[36px] rounded-[4px] flex justify-center items-center"
            style={{ background: colors.backgroundSide }}
          >
            <RiArrowRightSFill
              style={{
                fontSize: "20px",
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => {
                setIsShowTree(true);
              }}
            />
          </div>
        )}

        <MapContainer
          zoom={5}
          minZoom={4}
          touchZoom={(e) => {
            console.log(e);
          }}
          center={[21.028511, 105.804817]}
          scrollWheelZoom={true}
          // style={{ position: "relative" }}
          ref={mapRef}
          maxZoom={20}
          maxBounds={[
            [-30, 0],
            [30, 200],
          ]}
          zoomControl={false}
        >
          <div className="relative w-full h-[93vh] flex overflow-hidden">
            <CustomMap
              mapRef={mapRef}
              attribution={attribution}
              urlMap={urlMap}
              hightlight={hightlight}
            />
          </div>
        </MapContainer>
      </div>
    </DndProvider>
  );
};

export default MapSingle;
