import GridLayoutCustom from "@components/live/NX/grid-layout";
import colors from "@constants/colors";
import TreeCamera from "@components/live/custom-tree-camera";
import { useDispatch } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
const NX = () => {
  const [isShowTree, setIsShowTree] = useState(true);
  const widthTree = isShowTree ? 28 : -224;

  const dispatch = useDispatch();
  const loadDataProvince = () => {
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_PROVINCE,
      payload: {},
    });
  };
  const getAllCameraStreaming = () => {
    const callBack = (data) => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_ALL_CAMERA_STREAM,
      payload: { body: {}, callBack },
    });
  };

  useEffect(() => {
    loadDataProvince();
  }, []);
  return (
    <div className="h-full flex w-[100vw] space-x-1">
      {isShowTree ? (
        <div style={{ background: colors.backgroundSide }}>
          <TreeCamera />
        </div>
      ) : (
        ""
      )}
      {isShowTree ? (
        <div
          className="h-[36px] mt-[400px] rounded-[4px] flex justify-center items-center"
          style={{ background: colors.backgroundSide }}
        >
          <RiArrowLeftSFill
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setIsShowTree(false);
            }}
          />
        </div>
      ) : (
        <div
          className="h-[36px] mt-[400px] rounded-[4px] flex justify-center items-center"
          style={{ background: colors.backgroundSide }}
        >
          <RiArrowRightSFill
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setIsShowTree(true);
            }}
          />
        </div>
      )}
      <DndProvider backend={HTML5Backend}>
        <GridLayoutCustom widthTree={widthTree} />
      </DndProvider>
      {/* <TransformWrapper
        initialScale={2}
        zoomAnimation={{ animationTime: 1, animationType: "easeInOutCubic" }}
        //   centerOnInit={true}
        //   centerZoomedOut={true}
        maxScale={6}
        minScale={0.5}
        disablePan={true}
        disablePadding={true}
        //   smooth={true}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <TransformComponent>
              <DndProvider backend={HTML5Backend}>
                <div className="h-[100vh] w-[100vw]">
                  <GridLayout />
                </div>
              </DndProvider>
            </TransformComponent>
          </>
        )}
      </TransformWrapper> */}
    </div>
  );
};
export default NX;
