
import { SERVICE_PATHS } from "@constants/url";
import { get, get_stream, post } from "src/api/api-map";

const MapSingleService = {
  getDataCameraSearchMap: (body) => {
    var filterString = '?';
    Object.keys(body).map((index) => {
      if (filterString !== '?' && body[index] !== null) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.MAP.GET_LOCATION);
    } else {
      return get(`${SERVICE_PATHS.MAP.GET_LOCATION}${filterString}&status=true`);
    }
  },
};

export default MapSingleService;
