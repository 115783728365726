import Search from "@common/search/custom-search";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomTreeLoad from "@common/tree/tree-loader";
import { useEffect, useMemo, useRef, useState } from "react";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { imagesGroup } from "@common/images/image";
import { useDispatch, useSelector } from "react-redux";
import { handleDragItem, selectListProvince } from "@redux/slice/live-slice";
import { Tooltip, message } from "antd";
import { MdWeb } from "react-icons/md";
import { FaCameraRetro } from "react-icons/fa";
import CustomModal from "@common/modal/modal";
import CreateModalCamera from "./NX/content-modal-create-came";
import TreeContent from "./custom-tree-layout";
import { FaRegFileAlt } from "react-icons/fa";
import { HiServerStack } from "react-icons/hi2";
import CustomTreeWebPage from "@common/tree/tree-webpages";
import { LuLayoutList } from "react-icons/lu";
import CustomTreeServer from "@common/tree/tree-server";
import CreateModalGroup from "./NX/content-modal-create-group";
import CustomTreeFile from "@common/tree/tree-file";
import PopConfirm from "@common/pop-confim/pop-confim";
import { TbLayout } from "react-icons/tb";
import TreeLayout from "@common/tree/tree-layout";
import SaveLayout from "./NX/content-modal-save-layout";
import { PiLayoutBold } from "react-icons/pi";
const TreeCamera = () => {
  const onChangeAutoComplete = () => {};
  const [dataTree, setDataTree] = useState();
  const dataListTree = useSelector(selectListProvince);
  const [openCreateCamera, setOpenCreateCamera] = useState(false);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);

  const camRef = useRef({
    address_ids: null,
    district_ids: null,
    ids: null,
    ips: null,
    page_index: 1,
    page_size: 12,
    province_ids: null,
    status: true,
  });

  const dispatch = useDispatch();
  const confirmDelete = (e) => {
    console.log(e);
    message.success("Click on Yes");
  };

  const CameraSingle = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          type: "type" in node ? "frame" : "single",
          url: node.id,
          name: node.name,
        };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <Tooltip
        placement="right"
        zIndex={1}
        title={
          <div className="h-[135px] w-[240px] bg-white flex items-center justify-center">
            <span className="text-black font-black">NO VIDEO</span>
          </div>
        }
      >
        <div
          className="flex justify-center items-center"
          key={key}
          ref={drag}
          style={{ color: colors.text }}
        >
          <img
            style={{ marginRight: "5px" }}
            src={
              node.camera
                ? node.status
                  ? imagesGroup.camera_connected
                  : imagesGroup.camera_disconnected
                : null
            }
            alt=""
          />
          <span
            className="truncate max-w-[140px]"
            style={{
              color: `${
                node.show
                  ? node.status
                    ? colors.color_camera_connected
                    : colors.redGlobal
                  : colors.textPlaceholder
              }`,
            }}
          >
            {name}
          </span>
        </div>
      </Tooltip>
    );
  };

  const confirm = (e) => {
    console.log(e);
  };
  const cancel = (e) => {
    console.log(e);
  };

  const CameraGroup = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = { type: "group", id: node.id };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <div
        key={key}
        ref={drag}
        className="truncate flex justify-center items-center"
        style={{ color: colors.textBase }}
      >
        <img
          style={{ marginRight: "5px" }}
          src={imagesGroup.folder_default}
          alt=""
        />
        <PopConfirm
          description={
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => setOpenCreateCamera(true)}
            >
              <FaCameraRetro />
              <p>Tạo mới camera</p>
            </div>
          }
          context={<div className="truncate max-w-[180px]">{name}</div>}
          onCancel={cancel}
          onConfirm={confirm}
        />
      </div>
    );
  };
  const CameraLayout = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = { type: "layout", node: node };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <div
        key={key}
        ref={drag}
        className="truncate flex justify-center items-center"
        style={{ color: colors.textBase }}
      >
        <PiLayoutBold style={{ fontSize: 20 }} />
        <div className="ml-[5px] truncate max-w-[180px] font-semibold text-[16px]">
          {name}
        </div>
      </div>
    );
  };

  const memoizedTitleRender = useMemo(() => {
    return (node) => {
      const hasChildren = node.children && node.children.length >= 0;
      if (hasChildren) {
        return <CameraGroup key={node.id} node={node} name={node.title} />;
      } else {
        return <CameraSingle key={node.id} node={node} name={node.title} />;
      }
    };
  }, []);
  const memoizedTitleRenderGroup = useMemo(() => {
    return (node) => {
      const hasChildren = node.children && node.children.length >= 0;
      if (hasChildren) {
        return <CameraGroup key={node.id} node={node} name={node.title} />;
      } else {
        return <CameraSingle key={node.id} node={node} name={node.title} />;
      }
    };
  }, []);
  const memoizedTitleRenderLayout = useMemo(() => {
    return (node) => {
      return <CameraLayout key={node.id} node={node} name={node.title} />;
    };
  }, []);

  useEffect(() => {
    mapData(dataListTree);
  }, [dataListTree]);

  const mapData = (data) => {
    const arr = [];
    data?.map((item) => {
      const key = item.id;
      if (item.id === 1) {
        // console.log(item)
      }
      const treeNode = {
        title: item.name,
        key,
        id: item.id,
        isLeaf: false,
        children: [],
      };
      arr.push(treeNode);
    });
    setDataTree(arr);
  };
  useEffect(() => {
    const callBack = () => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_DATA_CAMERA_WITH_PROVINCE,
      payload: { body: camRef.current, callBack },
    });
  }, []);
  return (
    <DndProvider backend={HTML5Backend}>
      <Search
        placeholder={"Tìm kiếm"}
        onChangeAutoComplete={(value) => onChangeAutoComplete(value)}
      />
      <div className="h-[900px] w-[248px] scroll_default overflow-auto">
        <CustomModal
          content={
            <CreateModalCamera
              handleCancel={() => {
                setOpenCreateCamera(false);
              }}
            />
          }
          footer={false}
          width={"800px"}
          title={"Thêm mới camera"}
          open={openCreateCamera}
          handleCancel={() => {
            setOpenCreateCamera(false);
          }}
        />
        <CustomModal
          content={
            <CreateModalGroup
              handleCancel={() => {
                setOpenCreateGroup(false);
              }}
            />
          }
          footer={false}
          width={"800px"}
          title={"Thêm mới Nhóm"}
          open={openCreateGroup}
          handleCancel={() => {
            setOpenCreateGroup(false);
          }}
        />

        <TreeContent
          title={"Group Camera"}
          content={
            <CustomTreeLoad
              initTreeData={dataTree}
              height={228}
              // onSelect={onSelect}
              data={dataListTree}
              titleRender={memoizedTitleRender}
            />
          }
          icon={
            <HiServerStack
              style={{
                fontSize: "20px",
                color: "white",
              }}
            />
          }
        />
        <TreeContent
          title={"Web Pages"}
          content={<CustomTreeWebPage titleRender={memoizedTitleRender} />}
          icon={
            <MdWeb
              style={{
                fontSize: "20px",
                color: "white",
              }}
            />
          }
        />
        <TreeContent
          title={"Local File"}
          content={<CustomTreeFile titleRender={memoizedTitleRender} />}
          icon={
            <FaRegFileAlt
              style={{
                fontSize: "20px",
                color: "white",
              }}
            />
          }
        />
        <TreeContent
          title={"Server"}
          content={<CustomTreeServer titleRender={memoizedTitleRenderGroup} />}
          onCreateCamera={() => setOpenCreateCamera(true)}
          onCreateGroup={() => setOpenCreateGroup(true)}
          icon={
            <LuLayoutList
              style={{
                fontSize: "20px",
                color: "white",
              }}
            />
          }
        />
        <TreeContent
          title={"Layout"}
          content={<TreeLayout titleRender={memoizedTitleRenderLayout} />}
          onCreateCamera={() => setOpenCreateCamera(true)}
          onCreateGroup={() => setOpenCreateGroup(true)}
          icon={
            <TbLayout
              style={{
                fontSize: "20px",
                color: "white",
              }}
            />
          }
        />
      </div>
    </DndProvider>
  );
};
export default TreeCamera;
