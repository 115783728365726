const LogoHalk = () => {
    return ( 
        <div className="flex absolute flex-center justify-center h-full w-full overflow-hidden top-[140px]">
        <img
          alt=""
          src="/logo_halk.svg"
          className="h-[60vh] w-auto opacity-25"
        />
      </div>
     );
}
 
export default LogoHalk;