import colors from "@constants/colors";
import { ConfigProvider, Popconfirm } from "antd";

const PopConfirm = ({ onCancel, onConfirm,context,description,title=null,icon=null }) => {
    return ( 
        <ConfigProvider
        theme={{
          token: {
            colorBgBase: colors.background,
            borderRadius: 0,
          },
        }}
      >
        <Popconfirm
          title={title}
          description={description}
          onConfirm={onConfirm}
          icon={icon}
          onCancel={onCancel}
          trigger={["contextMenu"]}
          placement="bottomRight"
          okText="Có"
          cancelText="Không"
        >
          {context}
        </Popconfirm>
      </ConfigProvider>
     );
}
 
export default PopConfirm;