const PATH = {
	LOGIN: '/login',
	MANAGERMENT: 'management',
	HOME: 'home',
    USER:'user',
    NET: 'net',
    CAMERA:'camera',
    STORAGE:'storage',
    SYSTEM:'system',
    LIVE:'live',
    MAP:'map',
    MAPSINGLE:'map-single',
    MAP_DETAIL:'map-detail',
    DEVICE_GROUP:'device-group',
    AI: 'ai',
    WARNING:"warning",
    PLAYBACK:'playback',
    EVENT:'event',
    SEARCH_AI:'ai-search',
    SEARCH: 'ai-search/search',
    SEARCH_CHECK: 'face-check/search',
    SEARCH_HUMAN_DETECTION: 'human-detection/search',
    SEARCH_VEHICLE_DETECTION: 'vehicle-detection/search',
    FACE_CHECK:'face-check',
    HUMAN_DETECTION:'human-detection',
    VEHICLE_DETECTION:'vehicle-detection',
    DASHBOARD:'dashboard',
    EVENT_MANAGEMENT:'event-management',
    VIEW_PERSON:'person/view/:id',
    VIEW_VEHICLE:'vehicle/view',
    PERSON:'person',
    VEHICLE:'vehicle',
    CREATE_VEHICLE:'vehicle/create',
    VIDEO_SEARCH:'video-search',
    GROUP_EVENT:'group-event',
    PROFILE: {
		GET_PROFILE: 'GET_PROFILE',
		GET_ALL_PROFILE: 'GET_ALL_PROFILE',
		ADD_PROFILE: 'ADD_PROFILE',
		UPDATE_PROFILE: 'UPDATE_PROFILE',
		GET_IMAGE: 'GET_IMAGE',
		DELETE: 'DELETE_PROFILE',
		PROFILE_SEARCH: 'PROFILE_SEARCH',
		GET_PROFILE_WITH_ID:"GET_PROFILE_WITH_ID",
		MERGE_PROFILE_CHECK:"MERGE_PROFILE_CHECK",
		MERGE_PROFILE:"MERGE_PROFILE"
	},
};
export default PATH;
