/* eslint-disable array-callback-return */
import { memo, useCallback, useEffect, useRef, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "@style/style-grid-layout.css";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import CustomViewVideo from "@common/video/custom-video";
import {
  handleActiveCamera,
  handleChangeLayout,
  handleDragItem,
  handleMinimize,
  handleRemoveToolBox,
  handleReset,
  handleSelectLayout,
  selectActiveCamera,
  selectColum,
  selectDrag,
  selectRow,
} from "@redux/slice/live-slice";
import Selecto from "react-selecto";
import {
  handleAddCameraLayout,
  selectListCameraLayout,
  removeItemById,
  handleUpdateCamera,
  selectToolBox,
  selectMinimize,
  selectItemDrag,
} from "@redux/slice/live-slice";
import IframeCustom from "@common/iframe/iframe";
import colors from "@constants/colors";
import ToolBox from "./toolbox-item";
import GridBackground from "./grid-bg";
import Warning from "./warning";
import LogoHalk from "./logo-bg";
import MenuRightClick from "@common/menu/right-click-menu";
import { ScreenHeight } from "@utils/index";
const ReactGridLayout = WidthProvider(RGL);
const GridLayout = ({ widthTree }) => {
  const dispatch = useDispatch();
  const selectID = useRef([]);
  const activeID = useSelector(selectActiveCamera);
  const { screenHeight, screenWidth } = ScreenHeight();
  const [isShowGrid, setIsShowGrid] = useState(false);
  const column = useSelector(selectColum);
  const row = useSelector(selectRow);
  const lengthLayout = Array(column * row).fill(null);
  const layout = useSelector(selectListCameraLayout);
  const ListToolBoxItem = useSelector(selectToolBox);
  const isShow = useSelector(selectMinimize);
  const [isShowToolBox, setIsShowToolBox] = useState(false);
  const isSwap = useRef(false);
  const itemDrag = useSelector(selectItemDrag);
  const iframeRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [hoverID, setHoverID] = useState(null);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const headerToolBox = isShowToolBox ? 50 : 0;
  const itemSwap = useRef(null);
  const handleClick = () => {
    setIsShowMenu(false);
  };
  const updateItem = (item) => {
    dispatch(handleUpdateCamera(item));
  };
  const removeToolBox = (item) => {
    dispatch(handleMinimize(null));
    setTimeout(() => {
      dispatch(handleRemoveToolBox(item));
    }, [200]);
  };
  const itemInToolBox = ListToolBoxItem.find(
    (item) => item.id === itemDrag?.url
  );
  const HandleChange = (newLayout, oldLayout) =>
    dispatch(
      handleUpdateCamera({
        listNewLayOut: newLayout,
        listOldLayOut: oldLayout,
        type: "ChangeSize",
      })
    );
  const handleKeyDown = async ({ key }) => {
    if (key === "Delete") {
      await Promise.all(
        selectID.current.map(async (item) => {
          await dispatch(
            removeItemById({ idCamera: Number(item), status: "remove" })
          );
          await dispatch({
            type: TYPE_ACTION.LIVE.STOP_STREAM,
            payload: { id: Number(item), callBack: () => {} },
          });
        })
      );
    }
  };

  useEffect(() => {
    getAllCameraStreaming();
    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClick);
    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    const handleMessageFromIframe = (event) => {
      if (event.data.type === "MAP_EVENT") {
        dispatch(handleDragItem(event.data.payload));
      }
    };
    window.addEventListener("message", handleMessageFromIframe);

    return () => {
      window.removeEventListener("message", handleMessageFromIframe);
    };
  }, []);

  const getAllCameraStreaming = () => {
    const callBack = (data) => {
      data.forEach(({ id, urlRestream, ipAddress }, index) => {
        dispatch(
          handleAddCameraLayout({
            id,
            url: urlRestream,
            status: "Connect",
            index: `${index}`,
            frame: "camera",
            name: ipAddress,
          })
        );
      });
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_ALL_CAMERA_STREAM,
      payload: { body: {}, callBack },
    });
  };

  let lastWheelTime = 0;

  window.addEventListener("wheel", () => {
    lastWheelTime = Date.now();
  });

  setTimeout(() => {
    if (Date.now() - lastWheelTime > 500) {
      setIsShowGrid(false);
    }
  }, 500);

  const handleWheel = (event) => {
    setIsShowGrid(true);
    if (event.deltaY < 0) {
      if (row > 1 && column > 1) {
        dispatch(
          handleChangeLayout({
            row: row - 1,
            column: column - 1,
          })
        );
      }
    } else {
      if (row <= 10 && column <= 10) {
        dispatch(
          handleChangeLayout({
            row: row + 1,
            column: column + 1,
          })
        );
      }
    }
  };
  const handleDropItem = async (e) => {
    isSwap.current = false;
    setIsShowGrid(false);
    const updateData = ({
      id,
      url = "",
      status = "Disconnect",
      frame = "camera",
      groupType = false,
      x = e.x,
      y = e.y,
      name,
    }) => {
      return {
        id: id,
        url: url,
        status: status,
        frame: frame,
        x: x,
        y: y,
        groupType: groupType,
        column: column,
        name: name,
      };
    };
    // nhận data từ tab khác
    const channel = new BroadcastChannel("my-channel");
    channel.addEventListener("message", (event) => {
      updateItem(updateData({ id: event.data.url, status: "Loading" }));
      const callBack = (data) => {
        if (data) {
          data.forEach((item) => {
            if (item.urlRestream) {
              updateItem(
                updateData({
                  id: item.id,
                  url: item.urlRestream,
                  status: "Connect",
                })
              );
            }
          });
        }
      };

      const errorBack = (data) => {
        updateItem(updateData({ id: event.data.url }));
      };

      const payload = {
        url: event.data.url,
        callBack,
        errorBack,
      };

      dispatch({
        type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
        payload,
      });
    });

    // case item drag
    switch (itemDrag?.type) {
      case "single":
        {
          itemInToolBox && removeToolBox(itemInToolBox);
          updateItem(
            updateData({
              id: itemDrag.url,
              status: "Loading",
              name: itemDrag.name,
            })
          );
          const callBack = (data) => {
            if (data) {
              data.forEach((item) => {
                if (item.urlRestream) {
                  updateItem(
                    updateData({
                      id: item.id,
                      url: item.urlRestream,
                      status: "Connect",
                    })
                  );
                }
              });
            }
          };

          const errorBack = (data) => {
            updateItem(updateData({ id: itemDrag.url }));
          };

          const payload = {
            url: itemDrag.url,
            callBack,
            errorBack,
          };

          dispatch({
            type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
            payload,
          });
        }
        break;
      case "group":
        {
          const callBack = async (data) => {
            let listCameraMax = Math.min(data.length, lengthLayout.length);
            for (let i = 0; i < listCameraMax; i++) {
              let itemInToolBox = ListToolBoxItem.find(
                (item) => item.id === data[i].id
              );
              itemInToolBox && removeToolBox(itemInToolBox);
              updateItem(
                updateData({
                  id: data[i].id,
                  status: "Loading",
                  groupType: true,
                  x: 0,
                  y: 0,
                })
              );
              const callBack = (dataReturn) => {
                dataReturn &&
                  updateItem(
                    updateData({
                      id: dataReturn[0].id,
                      url: dataReturn[0].urlRestream,
                      status: "Connect",
                    })
                  );
              };
              const errorBack = (data) => {
                updateItem(updateData({ id: data[i].id }));
              };
              try {
                await dispatch({
                  type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
                  payload: { url: data[i].id, callBack, errorBack },
                });
              } catch (error) {}
            }
          };
          dispatch({
            type: TYPE_ACTION.LIVE.GET_WARD_LIVE,
            payload: { body: itemDrag.id, callBack },
          });
        }
        break;
      case "frame":
        updateItem(
          updateData({
            id: itemDrag.url,
            url: itemDrag.url,
            frame: "iframe",
            type: "",
            status: "Connect",
          })
        );
        break;
      case "layout":
        await Promise.all([
          dispatch(handleReset()),
          dispatch(
            handleChangeLayout({
              row: itemDrag?.node.row,
              column: itemDrag?.node.column,
            })
          ),
          dispatch(handleSelectLayout(itemDrag?.node?.children)),
        ]);
        break;
      default:
        break;
    }
  };
  const handleReconnect = useCallback((e) => {
    const reConnectData = ({ id, url = "", status = "Disconnect" }) => {
      return {
        id: id,
        url: url,
        status: status,
        frame: "camera",
        x: x,
        y: y,
        column: column,
      };
    };
    const callBack = (data) => {
      data &&
        data.forEach((item) => {
          item.urlRestream &&
            updateItem(
              reConnectData({
                id: item.id,
                url: item.urlRestream,
                status: "Connect",
              })
            );
        });
    };
    const errorBack = (data) => {
      updateItem(reConnectData({ id: itemDrag.url }));
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
      payload: { url: e, callBack, errorBack },
    });
  }, []);
  return (
    <div
      className=" h-[94vh] w-[100vw] overflow-hidden scroll_default relative "
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <MenuRightClick
        x={x}
        y={y}
        isShowMenu={isShowMenu}
        widthTree={widthTree}
        headerToolBox={headerToolBox}
      />

      <LogoHalk />
      <ToolBox
        isShowToolBox={isShowToolBox}
        setIsShowToolBox={setIsShowToolBox}
      />
      <Selecto
        selectableTargets={[".target"]}
        dragContainer={document.getElementById("container_grid")}
        hitRate={0}
        selectFromInside={false}
        checkInput
        boundContainer
        onSelectStart={(e) => {
          selectID.current = [];
          e.added.forEach((el) => {
            el.classList.add("selected");
          });
          e.removed.forEach((el) => {
            el.classList.remove("selected");
          });
        }}
        onSelectEnd={(e) => {
          e.afterAdded.forEach((el) => {
            el.classList.add("selected");
            const classList = el.className.split(" ");
            const id = classList.find((cls) => /^\d+$/.test(cls));
            if (!selectID.current.includes(id)) {
              selectID.current.push(id);
              dispatch(handleActiveCamera(id));
            }
          });
          e.afterRemoved.forEach((el) => {
            el.classList.remove("selected");
          });
        }}
      />
      {isShowGrid && (
        <GridBackground
          column={column}
          row={row}
          lengthLayout={lengthLayout}
          scale={scale}
          headerToolBox={headerToolBox}
          widthTree={widthTree}
        />
      )}
      <div
        onWheel={handleWheel}
        onContextMenu={(e) => {
          e.preventDefault();
          const width = 250 - widthTree;
          const height = 95 - headerToolBox;
          const x = e.pageX - width + 256;
          const y = e.pageY - height + 224;
          const tempScreenWidth = screenWidth - width;
          const tempScreenHeight = screenHeight - height;
          const tempX = x - widthTree - 280;
          setX(x > tempScreenWidth ? tempScreenWidth - 300 : tempX);
          setY(y > tempScreenHeight ? tempScreenHeight - 240 : y - 200);
          setIsShowMenu(true);
        }}
      >
        <ReactGridLayout
          // allowOverlap={true}
          // onLayoutChange={onLayoutChange}
          // useCSSTransforms={true}
          // measureBeforeMount={false}
          isBounded={true}
          compactType={null}
          preventCollision={true}
          layout={layout}
          cols={column}
          rowHeight={(screenHeight - 95 - headerToolBox) / row}
          items={20}
          isDraggable={useSelector(selectDrag)}
          isDroppable={true}
          isResizable={true}
          containerPadding={[0, 0]}
          style={{
            height: "90vh",
            width: "100%",
            transform: `scale(${scale})`,
            transformOrigin: "center center",
          }}
          margin={[0, 0]}
          onDragStart={(e, p) => {
            setIsShowGrid(true);
            isSwap.current = true;
          }}
          onResizeStart={() => {
            setIsShowGrid(true);
          }}
          onDragStop={(e, p) => {
            HandleChange(e, layout);
            setIsShowGrid(false);
            isSwap.current = false;
            // dispatch(handleSwap({idCameraSelect:Number(selectID.current), idCameraSwap:itemSwap.current,layout:layout}))
          }}
          onResizeStop={(e, p, i) => {
            HandleChange(e, layout);
            setIsShowGrid(false);
          }}
          onDropDragOver={(w, h) => {
            setIsShowGrid(true);
          }}
          onResize={(e) => {}}
          draggableHandle=".headerControl"
          resizeHandles={["nw", "se", "ne", "sw"]}
          onDrop={(e, item) => {
            handleDropItem(item);
          }}
        >
          {layout.map((item, index) => {
            return (
              <div
                key={index}
                data-grid={item}
                className={`target p-2 ${item.id} ${
                  item.id === isShow ? "slide-out-bck-tl" : ""
                }`}
                style={{
                  display: item.status === "remove" && "none",
                }}
                onMouseMove={(e) => {
                  setHoverID(item.id);
                }}
                onMouseLeave={() => {
                  setHoverID(null);
                }}
                onMouseEnter={(e) => {
                  itemSwap.current = item.id;
                }}
              >
                {item.isWarning && <Warning />}
                <div
                  className={`headerControl ${
                    item.isWarning && "absolute top-0 left-0"
                  } h-full w-full`}
                  style={{
                    background: colors.bg_color,
                    border: `1px solid ${
                      Number(activeID) === item.id ? "#04AA6D" : ""
                    }`,
                    // transform:`scale(${Number(activeID.current) === item.id ?'1.2':'1'})`,
                  }}
                >
                  {item.frame === "camera" ? (
                    <CustomViewVideo
                      url={item.url}
                      actionCamera={item.status}
                      idCamera={item.id}
                      item={item}
                      hoverID={hoverID}
                      nameCamera={item.name}
                      callBackRightMenu={() => setIsShowMenu(false)}
                      callBackError={(e) => {
                        updateItem({
                          id: e,
                          url: "",
                          status: "Reconnect",
                          frame: "camera",
                          column: column,
                        });
                        const callBack = (data) => {
                          handleReconnect(e);
                        };
                        dispatch({
                          type: TYPE_ACTION.LIVE.STOP_STREAM,
                          payload: { id: e, callBack },
                        });
                      }}
                    />
                  ) : (
                    <IframeCustom
                      item={item}
                      iframeRef={iframeRef}
                      hoverID={hoverID}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </ReactGridLayout>
      </div>
    </div>
  );
};
export default memo(GridLayout);
