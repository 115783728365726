import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	lng: 'en',
	resources: {
		en: {
			translation: {
				event_management: 'Event Management',
				identity_object_managements: 'Identity Object Managements',
				person: 'Person',
				vehicle: 'Vehicle',
				group: 'Group',
				notification: 'Notification',
				search: 'Search',
				name: 'Name',
				license_plate: 'License Plate',
				time: 'Time',
				camera: 'Camera',
				appear: 'Appear',
				cancel: 'Cancel',
				event: 'Event',
				error_img: 'Can\'t load image',
				status: 'Status',
				type: 'Type',
				brand: 'Brand',
				color: 'Color',
				username: 'Username',
				all: 'All',
				face_recognition: 'Face Recognition',
				vehicle_recognition: 'Vehicle Recognition',
				face_vehicle_access: 'Access control',
				crowd_detection: 'Crowd Detection',
				ai_flows: 'AI Flows',
				from: 'From',
				to: 'To',
				image: 'Image',
				action: 'Action',
				info: 'View',
				edit: 'Edit',
				person_list: 'Person List',
				add: 'Add',
				photo: 'Photo',
				no_photo: 'No photo',
				group_name: 'Group name',
				date_of_birth: 'Date of Birth',
				gender: 'Gender',
				permanent_resident: 'Permanent Resident',
				phone_number: 'Phone Number',
				male: 'Male',
				female: 'Female',
				other: 'Other',
				save: 'Save',
				back: 'Back',
				vehicle_list: 'Vehicle List',
				delete: 'Delete',
				new_profile: 'New Profile',
				identity_object_list: 'Identity Object List',
				description: 'Description',
				group_list: 'Group List ',
				object: 'Object',
				crowd: 'Crowd',
				object_quantity: 'Quantity',
				license_plate_type: 'License Plate Type',
				goverment_agencies: 'Goverment Agencies',
				military: 'Military',
				service: 'Service',
				diplomatic: 'Diplomatic',
				dashboard: 'Dashboard',
				place_of_origin: 'Place of origin',
				nationality: 'Nationality',
				misshapen: 'Misshapen',
				permanent_resident: 'Permanent Resident',
				profile: 'Profile',
				update_your_photo: 'Update your photo and personal details',
				update: 'Update',
				owner: 'Owner',
				confirm_delete: 'Confirm delete',
				ok: 'Ok',
				agree_to_delete_this_list: 'Agree to delete this list?',
				this_field_is_required: 'This field is required',
				email_format_is_not_correct: 'Email format is not correct!',
				delete_profile: 'Delete profile',
				no_data: 'No data',
				red: 'Red',
				green: 'Green',
				blue: 'Blue',
				black: 'Dark',
				orange: 'Orange',
				yellow: 'Yellow',
				white: 'Light',
				bike: 'Bike',
				motor: 'Motor',
				car: 'Car',
				truck: 'Truck',
				bus: 'Bus',
				similarity_Face_List: 'Similarity Face List',
				identity_face_list: 'Identity Face List',
				view_all: 'View all',
				object_information: 'Object Information',
				identity_history: 'Identity History',
				identity_image: 'Identity Image',
				identity_ratio: 'Identity Ratio',
				relevant: 'Relevant',
				location: 'Location',
				identity_vehicle_list: 'Identity Vehicle List',
				vehicle_registration_information: 'Vehicle Registration Information',
				owner_address: 'Owner address',
				registered_brand: 'Registered Brand',
				registered_model: 'Registered Model',
				object_name: 'Object Name',
				score: 'Score',
				event_warning: 'Event warning',
				notification_event: 'Notification Event',
				notification: 'Notification',
				unread: 'Unread',
				today: 'Today',
				yesterday: 'Yesterday',
				otherday: 'Other Days',
				warning_context: 'Warning Context',
				whenever_human_appears: 'Whenever Human Appears',
				warning_group: 'Warning Group',
				frequency: 'Frequency',
				within: 'within',
				hour: 'hour',
				start_time: 'Start time',
				end_time: 'End time',
				select_all_ai: 'Select all AI Flows',
				upload: 'Upload',
				reset: 'Reset',
				counting: 'Counting',
				when_human_appear: 'When human appear',
				when_vehicle_appear: 'When vehicle appear',
				enter_the_value_without_accents: 'Enter the value without accents',
				processing_please_wait: 'Processing please wait',
				drag_an_image_here_or: 'Drag an image here or',
				upload_a_file: 'upload a file',
				total: 'Total',
				quantity_per_page: 'Quantity per page',
				account_does_not_exist: 'account does not exist',
				stt: 'N.O',
				license_plate_already_exists: 'license plate already exists',
				statistics: 'Statistics',
				cycle: 'Cycle',
				vehicle_brand: 'Vehicle type',
				sevent_days_near: '7 days near',
				vehicle_in_out: 'Vehicle in - out',
				vehicle_type: 'Vehicle type',
				vehicle_color: 'Vehicle color',
				ten_cars_appeared_recently: '10 cars appeared recently',
				current_month: 'Current month',
				vehicle_in: 'Vehicle in',
				vehicle_out: 'Vehicle out',
				date: 'Date',
				statistics_by_vehicle_type: 'Statistics by vehicle type',
				statistics_by_vehicle_color: 'Statistics by vehicle color',
				statistics_by_vehicle_in_out: 'Statistics by vehicle in - out',
				sum_in_out: 'Total of vehicles entry - out',
				face_search:"Social Face Search",
				choose_file:"Choose File",
				number:"Number",
				threshold:"Threshold",
				require_image:"Video cannot be empty",
				user_name:"User name",
				search_time:"Search Time",
				show:"Show",
				you_may_be_known:"You may be known",
				profile_quality:"Profile quality",
				face_angle_photo:"Face angle photo",
				obscured_face:"Obscured face",
				p_good:"Good",
				p_medium:"Medium",
				p_low:"Low",
				p_bad:"Bad",
				add_image_search:"added successfully",
				video_search:"Intelligent Profiles",
				video_source:"VIDEO SOURCE",
				input:"INPUT",
				drag_video:"Drag any video here or",
				handle:"Handle",
				processing:"Processing",
				face_enhance:"Face Enhance",
				result:"Results",
				responseJSON:"Response JSON",
				email:"Email",
				detailt:"Detail",
				add_new:"Add new",
				number_of_objects:"Number of objects",
				map:"Map",
				original_image:"Original image",
				image_after_enhancement:"Image after enhancement",
				page:"Page",
				go_to:"Go to",
				click:"Click",
				replace:"to replace the new photo in the profile",
				enhance:"Enhance",
				frequencys:"Frequency",
				sort_by_create_date:"Sort by Date Created",
				sort_by_rank:"Sort by Rank",
				too_many_faces_detected:"Too many faces detected",
				face_is_too_small:"Face is too small",
				input_image_is_too_small:"Input image is too small",
				no_face_detected:"No face detected",
				merge_profile:"Merge profile",
				no:"No",
				yes:"Yes",
				merge:"Merge",
				checking:"Checking...",
				delete_the_event:"Delete the event",
				are_you_sure_event:"Are you sure to delete this event?",
				delete_the_profile:"Delete the profile",
				are_you_sure_profile:"Are you sure to delete this profile?",
				delete_the_vehicle:"Delete the vehicle",
				are_you_sure_vehicle:"Are you sure to delete this vehicle?",
				delete_the_group:"Delete the group",
				are_you_sure_group:"Are you sure to delete this group?",
				similar:"have similarity",
				do_you_want_merge:"Do you want to merge profile?",
				form_validation_failed: 'Form validation failed!',
			},
		},
		vn: {
			translation: {
				event_management: 'Quản lý sự kiện',
				identity_object_managements: 'Quản lý đối tượng',
				person: 'Người',
				vehicle: 'Phương tiện',
				group: 'Nhóm',
				notification: 'Thông báo',
				search: 'Tìm kiếm',
				name: 'Tên',
				license_plate: 'BSX',
				time: 'Thời gian',
				camera: 'Camera',
				appear: 'Xuất hiện',
				cancel: 'Thoát',
				event: 'Sự kiện',
				error_img: 'Không thể hiện ảnh',
				status: 'Trạng thái',
				type: 'Loại',
				brand: 'Nhãn hiệu',
				color: 'Màu sắc',
				username: 'Định danh',
				all: 'Tất cả',
				face_recognition: 'Nhận dạng khuôn mặt',
				vehicle_recognition: 'Nhận dạng phương tiện',
				face_vehicle_access: 'Kiểm soát ra vào',
				crowd_detection: 'Phát hiện đám đông',
				ai_flows: 'Bài toán AI',
				from: 'Từ',
				to: 'Đến',
				image: 'Hình ảnh',
				action: 'Hoạt động',
				info: 'Thông tin',
				edit: 'Chỉnh sửa',
				person_list: 'Danh sách người',
				add: 'Thêm',
				photo: 'Có ảnh',
				no_photo: 'Không có ảnh',
				group_name: 'Tên nhóm',
				date_of_birth: 'Ngày sinh',
				gender: 'Giới tính',
				permanent_resident: 'Thường trú',
				phone_number: 'Số điện thoại',
				male: 'Nam',
				female: 'Nữ',
				other: 'Khác',
				save: 'Lưu',
				back: 'Quay lại',
				vehicle_list: 'Danh sách phương tiện',
				delete: 'Xoá',
				new_profile: 'Thêm hồ sơ mới ',
				identity_object_list: 'Danh sách đối tượng',
				description: 'Mô tả',
				group_list: 'Danh sách nhóm',
				object: 'Đối tượng',
				crowd: 'Đám đông',
				object_quantity: 'Số lượng',
				license_plate_type: 'Loại biển số',
				goverment_agencies: 'Cơ quan chính phủ',
				military: 'Quân đội',
				service: 'Dịch vụ',
				diplomatic: 'Ngoại giao',
				dashboard: 'Trang quản trị',
				place_of_origin: 'Quê quán',
				nationality: 'Quốc tịch',
				misshapen: 'Đặc điểm nhận dạng',
				permanent_resident: 'Nội trú',
				profile: 'Hồ sơ',
				update_your_photo: 'Cập nhật ảnh và thông tin cá nhân của bạn',
				update: 'Cập nhật',
				owner: 'Chủ sở hữu',
				confirm_delete: 'Xác nhận xoá',
				ok: 'Xác nhận',
				agree_to_delete_this_list: 'Đồng ý xóa danh sách này',
				this_field_is_required: 'Trường này là bắt buộc',
				email_format_is_not_correct: 'Chưa đúng định dạng Email',
				delete_profile: 'Xoá hồ sơ',
				no_data: 'Không có dữ liệu',
				red: 'Đỏ',
				green: 'Xanh lá',
				blue: 'Xanh dương',
				black: 'Tối',
				orange: 'Cam',
				yellow: 'Vàng',
				white: 'Sáng',
				bike: 'Xe đạp',
				motor: 'Xe máy',
				car: 'Ô tô',
				truck: 'Xe tải',
				bus: 'Xe bus',
				similarity_Face_List: 'Danh sách khuôn mặt giống nhau',
				identity_face_list: 'Danh sách khuôn mặt nhận dạng',
				view_all: 'Xem tất cả',
				object_information: 'Thông tin đối tượng',
				identity_history: 'Lịch sử nhận dạng',
				identity_image: 'Hình ảnh nhận dạng',
				identity_ratio: 'Tỷ lệ nhận dạng',
				relevant: 'Quan hệ',
				location: 'Vị trí',
				identity_vehicle_list: 'Danh sách xe nhận dạng',
				vehicle_registration_information: 'Thông tin đăng ký xe',
				owner_address: 'Địa chỉ chủ sở hữu',
				registered_brand: 'Thương hiệu đăng ký',
				registered_model: 'Người đăng ký',
				object_name: 'Tên đối tượng',
				score: 'Điểm',
				event_warning: 'Cảnh báo',
				notification_event: 'Thông báo sự kiện',
				notification: 'Thông báo',
				unread: 'Chưa đọc',
				today: 'Hôm nay',
				yesterday: 'Hôm qua',
				otherday: 'Ngày khác',
				warning_context: 'Bối cảnh cảnh báo',
				whenever_human_appears: 'Bất cứ có người xuất hiện',
				warning_group: 'Nhóm cảnh báo',
				frequency: 'Thường xuyên',
				within: 'ở trong',
				hour: 'giờ',
				start_time: 'Thời gian bắt đầu',
				end_time: 'Thời gian kết thúc',
				select_all_ai: 'Chọn tất cả các bài AI',
				upload: 'Tải lên',
				reset: 'Làm mới',
				counting: 'Đếm số lượng',
				when_human_appear: 'Bất kì người nào xuất hiện',
				when_vehicle_appear: 'Bất kì phương tiện nào xuất hiện',
				enter_the_value_without_accents: 'Nhập giá trị không dấu',
				processing_please_wait: 'Vui lòng đợi trong giây lát',
				drag_an_image_here_or: 'Kéo hình ảnh vào đây hoặc',
				upload_a_file: 'Tải lên một ảnh',
				total: 'Tổng',
				quantity_per_page: 'Số lượng trên mỗi trang',
				account_does_not_exist: 'Tài khoản không tồn tại',
				stt: 'STT',
				license_plate_already_exists: 'biển số xe đã tồn tại',
				statistics: 'Thống kê báo cáo',
				cycle: 'Chu kì',
				vehicle_brand: 'Loại xe',
				sevent_days_near: '7 ngày gần nhất',
				vehicle_in_out: 'Phương tiện vào - ra',
				vehicle_type: 'Loại phương tiện',
				vehicle_color: 'Màu phương tiện',
				ten_cars_appeared_recently: '10 xe xuất hiện gần nhất',
				current_month: 'Tháng hiện tại',
				vehicle_in: 'Xe ra',
				vehicle_out: 'Xe vào',
				date: 'Ngày',
				statistics_by_vehicle_type: 'Thống kê loại xe',
				statistics_by_vehicle_color: 'Thống kê màu sắc xe',
				statistics_by_vehicle_in_out: 'Thống kê phương tiện ra - vào',
				sum_in_out: 'Tổng số phương tiện vào- ra',
				face_search:"Tìm kiếm mạng xã hội",
				choose_file:"Chọn ảnh",
				number:"Số lượng",
				threshold:"Ngưỡng",
				require_image:"video không được trống",
				user_name:"Tên tài khoản",
				search_time:"Thời gian tìm kiếm",
				show:"Hiển thị",
				you_may_be_known:"Người có thể bạn biết",
				profile_quality:"Chất lượng hồ sơ",
				face_angle_photo:"Ảnh góc mặt",
				obscured_face:"Mặt bị che khuất",
				p_good:"Tốt",
				p_medium:"Trung bình",
				p_low:"Thấp",
				p_bad:"Tệ",
				add_image_search:"Thêm mới thành công",	
				video_search:"Hồ sơ thông minh",
				video_source:"VIDEO NGUỒN",
				input:"ĐẦU VÀO",
				drag_video:"Kéo bất kỳ video nào vào đây hoặc",
				handle:"Xử lí",
				processing:"Đang xử lí",
				face_enhance:"Tạo nét khuôn mặt",
				result:"Kết quả",
				responseJSON:"JSON",
				email:"Thư điện tử",
				detailt:"Chi tiết",
				add_new:"Thêm mới",
				number_of_objects:"Số lượng đối tượng",
				map:"Bản đồ",
				original_image:"Ảnh gốc",
				image_after_enhancement:"Hình ảnh sau khi nâng cao",
				page:"Trang",
				go_to:"Đi đến",
				click:"Nhấp chuột",
				replace:"để thay thế ảnh mới trong hồ sơ",
				enhance:"Nâng cấp",
				frequencys:"Tần suất",
				sort_by_create_date:"Sắp xếp theo ngày tạo",
				sort_by_rank:"Sắp xếp theo thứ hạng",
				too_many_faces_detected:"Đã phát hiện quá nhiều khuôn mặt",
				face_is_too_small:"Khuôn mặt quá nhỏ",
				input_image_is_too_small:"Hình ảnh đầu vào quá nhỏ",
				no_face_detected:"Không phát hiện thấy khuôn mặt nào",
				merge_profile:"Gộp hồ sơ",
				no:"Không",
				yes:"Có",
				merge:"Gộp",
				checking:"Kiểm tra...",
				delete_the_event:"Xoá sự kiện",
				are_you_sure_event:"Bạn có chắc chắn xóa sự kiện này không?",
				delete_the_profile:"Xóa hồ sơ",
				are_you_sure_profile:"Bạn có chắc chắn xóa hồ sơ này?",
				delete_the_vehicle:"Xóa phương tiện",
				are_you_sure_vehicle:"Bạn có chắc chắn xóa phương tiện này không?",
				delete_the_group:"Xóa nhóm",
				are_you_sure_group:"Bạn có chắc chắn xóa nhóm này?",
				similar:"có độ tương đồng",
				do_you_want_merge:"Bạn có muốn gộp hồ sơ?",
				form_validation_failed:'xác thực mẫu không thành công!',
			},
		},
		ru: {
			translation: {
				event_management: 'ОПЕРАЦИОННОГО УПРАВЛЕНИЯ',
				identity_object_managements: 'Quản lý đối tượng',
				person: 'Человек',
				vehicle: 'транспорт',
				group: 'группа',
				notification: 'уведомление',
				search: 'Поиск',
				name: 'Имя',
				license_plate: 'Номерной знак транспортного средства',
				time: 'Время',
				camera: 'камера',
				appear: 'появляться',
				cancel: 'выход',
				event: 'Sự kiện',
				error_img: 'izobrazheniye oshibki',
				status: 'статус',
				type: 'тип',
				brand: 'бренд',
				color: 'цвет',
				username: 'имя пользователя',
				all: 'все',
				face_recognition: 'Nhận dạng khuôn mặt',
				vehicle_recognition: 'Nhận dạng phương tiện',
				face_vehicle_access: 'Kiểm soát ra vào',
				crowd_detection: 'Phát hiện đám đông',
				ai_flows: 'Bài toán AI',
				from: 'из',
				to: 'чтобы',
				image: 'изображение',
				action: 'действие',
				info: 'информация',
				edit: 'редактировать',
				person_list: 'Список людей',
				add: 'добавить',
				photo: 'фото',
				no_photo: 'нет фото',
				group_name: 'название группы',
				date_of_birth: 'день рождения',
				gender: 'пол',
				permanent_resident: 'Thường trú',
				phone_number: 'телефонный номер',
				male: 'мужской',
				female: 'женщина',
				other: 'другое',
				save: 'Lưu',
				back: 'Quay lại',
				vehicle_list: 'список автомобиль',
				delete: 'удалить',
				new_profile: 'новый профиль',
				identity_object_list: 'Danh sách đối tượng',
				description: 'описание',
				group_list: 'группа списка',
				object: 'объект',
				crowd: 'толпа',
				object_quantity: 'количество',
				license_plate_type: 'Loại biển số',
				goverment_agencies: 'Cơ quan chính phủ',
				military: 'военные',
				service: 'сервис',
				diplomatic: 'дипломатический',
				dashboard: 'приборная панель',
				place_of_origin: 'место происхождения',
				nationality: 'национальность',
				misshapen: 'Đặc điểm nhận dạng',
				permanent_resident: 'Nội trú',
				profile: 'профиль',
				update_your_photo: 'Cập nhật ảnh và thông tin cá nhân của bạn',
				update: 'обновлять',
				owner: 'владелец',
				confirm_delete: 'подтвердить удалить',
				ok: 'ок',
				agree_to_delete_this_list: 'согласитесь удалить этот список',
				this_field_is_required: 'это поле обязательно',
				email_format_is_not_correct: 'формат электронной почты неверен',
				delete_profile: 'удалить профиль',
				no_data: 'нет данных',
				red: 'красный',
				green: 'зеленый',
				blue: 'синий',
				black: 'черный',
				orange: 'оранжевый',
				yellow: 'желтый',
				white: 'белый',
				bike: 'велосипеды',
				motor: 'мотор',
				car: 'автомобиль',
				truck: 'грузовик',
				bus: 'автобус',
				similarity_Face_List: 'Danh sách khuôn mặt giống nhau',
				identity_face_list: 'Danh sách khuôn mặt nhận dạng',
				view_all: 'посмотреть все',
				object_information: 'объектная информация',
				identity_history: 'история идентичности',
				identity_image: 'образ личности',
				identity_ratio: 'Tỷ lệ nhận dạng',
				relevant: 'Quan hệ',
				location: 'местоположение',
				identity_vehicle_list: 'идентификационный список транспортных средств',
				vehicle_registration_information: 'Thông tin đăng ký xe',
				owner_address: 'адрес владельца',
				registered_brand: 'зарегистрированный бренд',
				registered_model: 'зарегистрированная модель',
				object_name: 'Название объекта',
				score: 'Điểm',
				event_warning: 'Предупреждение о событии',
				notification_event: 'Уведомление событие',
				notification: 'Уведомление',
				unread: 'Непрочитанный',
				today: 'Сегодня',
				yesterday: 'Вчера',
				otherday: 'Другой день',
				warning_context: 'Предупреждающий контекст',
				whenever_human_appears: 'Всякий раз, когда появляется человек',
				warning_group: 'Группа предупреждения',
				frequency: 'Частота',
				within: 'Внутри',
				hour: 'Час',
				start_time: 'Время начала',
				end_time: 'Время окончания',
				select_all_ai: 'Chọn tất cả các bài AI',
				upload: 'Tải lên',
				reset: 'Сброс',
				counting: 'Подсчет',
				when_human_appear: 'Когда появляется человек',
				when_vehicle_appear: 'Bất kì phương tiện nào xuất hiện',
				enter_the_value_without_accents: 'Nhập giá trị không dấu',
				processing_please_wait: 'Vui lòng đợi trong giây lát',
				drag_an_image_here_or: 'Kéo hình ảnh vào đây hoặc',
				upload_a_file: 'Tải lên một ảnh',
				total: 'Всего',
				quantity_per_page: 'Số lượng trên mỗi trang',
				account_does_not_exist: 'Tài khoản không tồn tại',
				stt: 'STT',
				license_plate_already_exists: 'biển số xe đã tồn tại',
				statistics: 'Статистика',
				cycle: 'Chu kì',
				vehicle_brand: 'Loại xe',
				sevent_days_near: '7 ngày gần nhất',
				vehicle_in_out: 'Phương tiện vào - ra',
				vehicle_type: 'Loại phương tiện',
				vehicle_color: 'Màu phương tiện',
				ten_cars_appeared_recently: '10 xe xuất hiện gần nhất',
				current_month: 'Tháng hiện tại',
				vehicle_in: 'Автомобиль в',
				vehicle_out: 'Автомобиль выехал',
				date: 'Дата',
				statistics_by_vehicle_type: 'Thống kê loại xe',
				statistics_by_vehicle_color: 'Thống kê màu sắc xe',
				statistics_by_vehicle_in_out: 'Thống kê phương tiện ra - vào',
				sum_in_out: 'Tổng số phương tiện vào- ra',
				face_search:"Tìm kiếm mạng xã hội",
				choose_file:"Chọn ảnh",
				number:"Количество",
				threshold:"Ngưỡng",
				require_image:"video không được trống",
				user_name:"Имя пользователя",
				search_time:"Время поиска",
				show:"Hiển thị",
				you_may_be_known:"Người có thể bạn biết",
				profile_quality:"Chất lượng hồ sơ",
				face_angle_photo:"Ảnh góc mặt",
				obscured_face:"Mặt bị che khuất",
				p_good:"Tốt",
				p_medium:"Trung bình",
				p_low:"Thấp",
				p_bad:"Tệ",
				add_image_search:"Thêm mới thành công",	
				video_search:"Hồ sơ thông minh",
				video_source:"VIDEO NGUỒN",
				input:"ĐẦU VÀO",
				drag_video:"Kéo bất kỳ video nào vào đây hoặc",
				handle:"Xử lí",
				processing:"Đang xử lí",
				face_enhance:"Tạo nét khuôn mặt",
				result:"Kết quả",
				responseJSON:"JSON",
				email:"Электронная почта",
				detailt:"Деталь",
				add_new:"Добавить новый",
				number_of_objects:"Số lượng đối tượng",
				map:"Карта",
				original_image:"Ảnh gốc",
				image_after_enhancement:"Hình ảnh sau khi nâng cao",
				page:"Страница",
				go_to:"Перейти к",
				click:"Нажмите",
				replace:"Заменить",
				enhance:"Улучшать",
				frequencys:"Частоты",
				sort_by_create_date:"Сортировать по дате создания",
				sort_by_rank:"Sắp xếp theo thứ hạng",
				too_many_faces_detected:"Đã phát hiện quá nhiều khuôn mặt",
				face_is_too_small:"Khuôn mặt quá nhỏ",
				input_image_is_too_small:"Hình ảnh đầu vào quá nhỏ",
				no_face_detected:"Không phát hiện thấy khuôn mặt nào",
				merge_profile:"Gộp hồ sơ",
				no:"Нет",
				yes:"Да",
				merge:"Объединить",
				checking:"Проверка",
				delete_the_event:"Удалить событие",
				are_you_sure_event:"Вы уверены, что удалите событие?",
				delete_the_profile:"Удалить профиль",
				are_you_sure_profile:"Вы уверены, что удалите профиль?",
				delete_the_vehicle:"Удалить транспортное средство",
				are_you_sure_vehicle:"Bạn có chắc chắn xóa phương tiện này không?",
				delete_the_group:"Удалить группу",
				are_you_sure_group:"Вы точно удалите группу?",
				similar:"có độ tương đồng",
				do_you_want_merge:"Bạn có muốn gộp hồ sơ?",
				form_validation_failed:'Проверка формы не удалась!',
			},
		},
	},
});

export default i18n;
