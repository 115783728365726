import { takeEvery, put, call } from "redux-saga/effects";
import TYPE_ACTION from "@constants/action";
import {
  getSizeGrid,
  getOpenList,
  getListCameraPreview,
  getListProvince,
  getCameraWithProvince,
  handleCheckGrid,
  handleDataSearch,
  handleGetGroup,
  handleGetCamera
} from "@redux/slice/live-slice";
import LiveService from "@redux/services/live-services";
import { message } from "antd";

//---Worker

function* doGetListProvince(action) {
  const { ids } = action.payload;
  try {
    const response = yield call(LiveService.getDatalistProvince, ids);
    const { data } = response;
    yield put(getListProvince(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doSelectSizeGrid(action) {
  const body = action.payload;
  yield put(getSizeGrid(body));
}
function* doSelectOpenListCam(action) {
  const body = action.payload;
  yield put(getOpenList(body));
}

function* doGetListCameraPreview(action) {
  const body = action.payload;
  yield put(getListCameraPreview(body));
}
function* doGetCameraDataWithProvince(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.getCameraDataWithProvince, body);
    const { data } = response;
    callBack(data);
    yield put(getCameraWithProvince(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetCameraDataWithID(action) {
  const { url, callBack, errorBack } = action.payload;
  try {
    const response = yield call(LiveService.getPreviewCamWithID, url);
    callBack(response.data);
  } catch (error) {
    errorBack(error);
  }
}

function* doGetDistrict(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getDistric, body);
    callBack(response.data.results);
  } catch (error) {}
}

function* doGetWard(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getWard, body);
    callBack(response.data.results);
  } catch (error) {}
}

function* doGetDevice(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getDevice, body);
    callBack(response.data);
  } catch (error) {}
}

function* doGetAllCameraStream(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getAllCameraStream, body);
    callBack(response.data);
    yield put(handleCheckGrid(response.data));
  } catch (error) {}
}

function* doStopStream(action) {
  const { id, callBack } = action.payload;
  try {
    const response = yield call(LiveService.stopStream, id);
    callBack(response);
  } catch (error) {}
}

function* doStopAllStream(action) {
  const { id } = action.payload;
  yield call(LiveService.stopAllStream, id);
}
function* doCreateGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.createGroup, body);
    const { data } = response;
    callBack(data);
    message.success("Create success!");
  } catch (error) {
    console.log("Error update: ", error);
    message.error(error.response.data.message);
  }
}
function* doCreateCamera(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.createCamera, body);
    const { data } = response;
    callBack(data);
    message.success("Create success!");
  } catch (error) {
    console.log("Error update: ", error);
    message.error(error.response.data.message);
  }
}
function* doGetSearchCamera(action) {
  try {
    const { body, callback } = action.payload;
    const response = yield call(LiveService.getDataCameraSearch, body);
    const { data } = response;
    callback(data);
    yield put(handleDataSearch(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetGroup(action) {
  try {
    const { body } = action.payload;
    const response = yield call(LiveService.getGroup, body);
    const { data } = response;
    yield put(handleGetGroup(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetCameraNonAddress(action) {
  try {
    const { body } = action.payload;
    const response = yield call(LiveService.getCameraNonAddress, body);
    const { data } = response;
    yield put(handleGetCamera(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doDeleteCameraNonAddress(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.deleteCamera, body);
		callBack(response);
		message.success('Delete success!');
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* watchDoGetListProvince() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_LIST_PROVINCE, doGetListProvince);
}

function* watchDoSelectSizeGrid() {
  yield takeEvery(TYPE_ACTION.LIVE.CHANGE_GRID, doSelectSizeGrid);
}
function* watchDoSelectOpenList() {
  yield takeEvery(TYPE_ACTION.LIVE.OPEN_LIST, doSelectOpenListCam);
}
function* watchDoGetListCameraPreview() {
  yield takeEvery(
    TYPE_ACTION.LIVE.GET_LIST_CAMERA_PREVIEW,
    doGetListCameraPreview
  );
}
function* watchDoGetDataCameraWithProvince() {
  yield takeEvery(
    TYPE_ACTION.LIVE.GET_DATA_CAMERA_WITH_PROVINCE,
    doGetCameraDataWithProvince
  );
}
function* watchDoGetDataCameraWithID() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID, doGetCameraDataWithID);
}
function* watchDoGetDistrictLive() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_DISTRICT_LIVE, doGetDistrict);
}
function* watchDoGetWardLive() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_WARD_LIVE, doGetWard);
}
function* watchDoGetDevice() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_DEVICE_LIVE, doGetDevice);
}

function* watchDoGetAllCameraStream() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_ALL_CAMERA_STREAM, doGetAllCameraStream);
}

function* watchDoStopStream() {
  yield takeEvery(TYPE_ACTION.LIVE.STOP_STREAM, doStopStream);
}
function* watchDoStopAllStream() {
  yield takeEvery(TYPE_ACTION.LIVE.STOP_ALL_STREAM, doStopAllStream);
}
function* watchCreateGroup() {
  yield takeEvery(TYPE_ACTION.LIVE.CREATE_GROUP, doCreateGroup);
}
function* watchCreateCamera() {
  yield takeEvery(TYPE_ACTION.LIVE.CREATE_CAMERA, doCreateCamera);
}
function* watchSearchCameraMap() {
  yield takeEvery(TYPE_ACTION.LIVE.SEARCH_CAMERA, doGetSearchCamera);
}
function* watchGetGroup() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_LIVE_GROUP, doGetGroup);
}
function* watchGetCameraNonAddress() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_CAMERA_NON_ADDRESS, doGetCameraNonAddress);
}
function* watchDeleteCameraNonAddress() {
  yield takeEvery(TYPE_ACTION.LIVE.DELETE_CAMERA, doDeleteCameraNonAddress);
}
export {
  watchDoSelectSizeGrid,
  watchDoSelectOpenList,
  watchDoGetListCameraPreview,
  watchDoGetListProvince,
  watchDoGetDataCameraWithProvince,
  watchDoGetDataCameraWithID,
  watchDoGetDistrictLive,
  watchDoGetWardLive,
  watchDoGetDevice,
  watchDoGetAllCameraStream,
  watchDoStopStream,
  watchDoStopAllStream,
  watchCreateGroup,
  watchSearchCameraMap,
  watchGetGroup,
  watchCreateCamera,
  watchGetCameraNonAddress,
  watchDeleteCameraNonAddress
};
