import { DownOutlined } from "@ant-design/icons";
import colors from "@constants/colors";
import { selectListLayout } from "@redux/slice/live-slice";
import { ConfigProvider, Tree } from "antd";
import { useSelector } from "react-redux";

const TreeLayout = ({
  initTreeData,
  onSelect,
  data = [],
  titleRender,
  showIcon = true,
}) => {
  const treeWeb = useSelector(selectListLayout)
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "rgba(16, 16, 22, 0)",
          controlItemBgActive: colors.transparent,
          lineHeight: 2,
          colorText: colors.textBase,
          colorPrimary: colors.redGlobal,
          colorBorder: colors.borderColor,
          paddingXS: 5,
        },
      }}
    >
      <Tree
        checkable={false}
        treeData={treeWeb}
        showIcon={showIcon}
        height={233}
        switcherIcon={
          <DownOutlined size={24} style={{ height: "24px", width: "24px" }} />
        }
        showLine={true}
        onSelect={onSelect}
        titleRender={titleRender}
      />
    </ConfigProvider>
  );
};

export default TreeLayout;
