import { createSlice } from "@reduxjs/toolkit";
export const liveSlice = createSlice({
  name: "live",
  initialState: {
    isOpenListCamera: false,
    listCameraStream: [],
    listProvince: [],
    dataCameraWithProvince: [],
    dataPreviewCamWithID: null,
    actionCamera: "",
    randomRender: 0,
    selectedCamera: 0,
    listLayoutCamera: [],
    listLayoutUpdateCamera: [],
    toolBox: [],
    minimize: 0,
    idCameraSelect: 0,
    idCameraActive: [],
    isWarning: false,
    itemDrag: null,
    group: [],
    dataSearch: [],
    cameraNonAddress: [],
    layout: [],
    listLayout: [],
    isDragActive: true,
    column: 1,
    row: 1,
    listPolygon: [],
    pointsPolygon: [],
    isChangePointPolygon: false,
  },
  reducers: {
    getListProvince: (state, action) => {
      state.listProvince = action.payload.results;
    },
    getOpenList: (state, action) => {
      state.isOpenListCamera = action.payload;
    },
    getListCameraPreview: (state, action) => {
      state.listCameraPreview[action.payload.index] = {
        index: action.payload.index,
        url: action.payload?.url,
        id: action.payload?.id,
        status: action.payload.status,
      };
      state.listCameraStream[action.payload.index] = {
        index: action.payload.index,
        url: action.payload?.url,
        id: action.payload?.id,
        status: action.payload.status,
      };
    },
    getCameraWithProvince: (state, action) => {
      state.dataCameraWithProvince = action.payload;
    },
    getPreviewCameWithID: (state, action) => {
      state.dataPreviewCamWithID = action.payload;
    },
    changeCameraStatus: (state, action) => {
      state.actionCamera = action.payload;
    },
    randomRender: (state, action) => {
      state.randomRender += action.payload;
    },
    resetgetListCameraPreview: (state, action) => {
      const { index, listCameraStream } = action.payload;
      state.listCameraPreview[index] = null;
      const newArray = [...listCameraStream];
      const idIndex = newArray.findIndex(
        (item) => (item && item.index) === index
      );
      if (idIndex !== -1) {
        newArray.splice(idIndex, 1);
      }
      state.listCameraStream = newArray;
    },
    handleCameraById: (state, action) => {
      state.selectedCamera = action.payload;
    },
    handleStopAll: (state, action) => {
      // const {listStop} = action.payload;
      // listStop.map(item =>{
      //   console.log('item',item);
      // })
      // state.listCameraStream = []
    },
    handleAddCameraLayout: (state, action) => {
      const { id, url, status, index, frame, name } = action.payload;
      const existingItemIndex = state.listLayoutCamera.findIndex(
        (item) => item.id === id
      );
      if (existingItemIndex >= 0) {
        state.listLayoutCamera[existingItemIndex] = {
          ...state.listLayoutCamera[existingItemIndex],
          url: url,
          status: status,
          frame: frame,
        };
      } else {
        const cols = 5;
        let maxX = 0;
        let maxY = 0;

        state.listLayoutCamera.forEach((item) => {
          if (item.y > maxY) {
            maxY = item.y;
            maxX = item.x;
          } else if (item.y === maxY && item.x > maxX) {
            maxX = item.x;
          }
        });

        let newItemX = maxX + 1;
        let newItemY = maxY;

        if (newItemX >= cols) {
          newItemX = 0;
          newItemY = maxY + 1;
        }
        const newItem = {
          w: 1,
          h: 1,
          x: newItemX,
          y: newItemY,
          i: `${index}`,
          id: id,
          url: url,
          status: status,
          frame: frame,
          name: name ? name : "",
        };
        state.listLayoutCamera.push(newItem);
      }
    },
    handleUpdateCamera: (state, action) => {
      const { listNewLayOut, listOldLayOut, type, column } = action.payload;
      const newUpdateCamera = [];

      const cols = column;
      let maxX = -1;
      let maxY = 0;
      let i = 0;

      state.listLayoutCamera.forEach((item) => {
        i++;
        if (item.y > maxY) {
          maxY = item.y;
          maxX = item.x;
        } else if (item.y === maxY && item.x > maxX) {
          maxX = item.x;
        }
      });
      let newItemX = maxX + 1;
      let newItemY = maxY;
      if (newItemX === cols) {
        newItemX = 0;
        newItemY = maxY + 1;
      }
      switch (type) {
        case "ChangeSize":
          listNewLayOut.forEach((itemNew) => {
            const item = listOldLayOut.find((item) => item.i === itemNew.i);
            if (item) {
              const updateLayOut = {
                ...item,
                w: itemNew.w,
                h: itemNew.h,
                x: itemNew.x,
                y: itemNew.y,
              };
              newUpdateCamera.push(updateLayOut);
            }
          });
          state.listLayoutCamera = newUpdateCamera;
          break;

        default:
          const {
            id,
            url,
            status,
            frame,
            x,
            y,
            w,
            h,
            isWarning,
            deg,
            groupType,
            name,
          } = action.payload;
          const existingItem = state.listLayoutCamera.find(
            (item) => item.id === id
          );
          if (existingItem) {
            if (url || id) {
              if (typeof id !== "string") {
                existingItem.url = url;
                existingItem.status = status;
              } else {
                existingItem.w = 1;
                existingItem.h = 1;
                existingItem.x = groupType ? newItemX : x;
                existingItem.y = groupType ? newItemY : y;
              }
            }
          } else {
            const newItem = {
              w: w ? w : 1,
              h: h ? h : 1,
              x: groupType ? newItemX : x,
              y: groupType ? newItemY : y,
              i: `${i}`,
              id: id,
              url: url,
              status: status,
              frame: frame,
              isWarning: false,
              deg: -1,
              name: name,
            };
            state.listLayoutCamera.push(newItem);
          }
          break;
      }
    },
    removeItemById: (state, action) => {
      const { idCamera, status } = action.payload;
      let arrRemove = [];
      const newItem = state.listLayoutCamera.map((item) => {
        if (item.id === idCamera) {
          return {
            ...item,
            id: "",
            w: 0,
            h: 0,
            x: 0,
            y: 0,
            url: null,
            status: status,
          };
        }
        return item;
      });
      state.listLayoutCamera = newItem;
      state.listLayoutCamera.forEach((item) => {
        if (item.url === null) {
          arrRemove.push(item);
        }
      });
      if (arrRemove.length === state.listLayoutCamera.length) {
        state.listLayoutCamera = [];
      }
    },
    warningItemById: (state, action) => {
      const { idCamera, isWarning } = action.payload;
      const newItem = state.listLayoutCamera.map((item) => {
        if (item.id === idCamera) {
          return {
            ...item,
            isWarning: isWarning,
          };
        }
        return item;
      });
      state.listLayoutCamera = newItem;
    },
    handleAddToolBox: (state, action) => {
      const itemToolBox = action.payload;
      state.toolBox.push(itemToolBox);
      const newItem = state.listLayoutCamera.map((item) => {
        if (item.id === itemToolBox.id) {
          return {
            ...item,
            w: 0,
            h: 0,
            x: 0,
            y: 0,
            status: "remove",
          };
        }
        return item;
      });
      state.listLayoutCamera = newItem;
    },
    handleRemoveToolBox: (state, action) => {
      const itemRemove = action.payload;
      state.toolBox = state.toolBox.filter(
        (item) => item.id !== action.payload.id
      );
      const item = state.listLayoutCamera.find(
        (item) => item.id === itemRemove.id
      );
      if (item) {
        item.w = itemRemove.w;
        item.h = itemRemove.h;
        item.x = itemRemove.x;
        item.y = itemRemove.y;
        item.status = "Connect";
      }
    },
    handleMinimize: (state, action) => {
      state.minimize = action.payload;
    },
    handleRotate: (state, action) => {
      const { idCamera, deg } = action.payload;
      const newItem = state.listLayoutCamera.map((item) => {
        if (item.id === idCamera) {
          return {
            ...item,
            deg: 90 * deg,
          };
        }
        return item;
      });
      state.listLayoutCamera = newItem;
    },
    handleChangeID: (state, action) => {
      state.idCameraSelect = 0;
      state.idCameraSelect = action.payload;
    },
    handleActiveCamera: (state, action) => {
      state.idCameraActive = [];
      state.idCameraActive = action.payload;
    },
    handleDragItem: (state, action) => {
      state.itemDrag = action.payload;
    },
    handleWarning: (state, action) => {
      state.isWarning = action.payload;
    },
    handleDataSearch(state, action) {
      let arr = [];
      action.payload.results.forEach((item) => {
        arr.push({
          label: item.name,
          info: item,
          value: `${item.id}`,
        });
      });
      state.dataSearch = arr;
    },
    handleGetGroup(state, action) {
      let arr = [];
      action.payload.forEach((item, index) => {
        arr.push({
          title: item.name,
          key: item.id,
          id: item.id,
          isLeaf: false,
          children: item.cameraIds.map((i) => {
            return {
              title: i,
              key: `${item.id}${i}`,
              id: i,
              isLeaf: true,
            };
          }),
        });
      });
      state.group = arr;
    },
    handleGetCamera(state, action) {
      state.cameraNonAddress = action.payload;
    },
    handleAddNewLayout(state, action) {
      const { listCamera, nameLayout, row, column } = action.payload;
      const listLayoutSave = listCamera.filter(
        (item) => item.status !== "Remove"
      );
      const newLayout = {
        title: nameLayout.name,
        key: `${nameLayout.name}layout`,
        camera: true,
        status: true,
        isLeaf: true,
        id: `${nameLayout.name}`,
        url: `${nameLayout.name}`,
        type: "layout",
        children: listCamera,
        row: row,
        column: column,
      };
      state.listLayoutCamera = listCamera.filter(
        (item) => !listLayoutSave.includes(item)
      );
      state.layout.push(newLayout);
    },
    handleSelectLayout(state, action) {
      state.listLayoutCamera = action.payload;
    },
    handleSelect(state, action) {
      state.isDragActive = action.payload;
    },
    handleChangeLayout(state, action) {
      const { row, column } = action.payload;
      state.row = row;
      state.column = column;
    },
    handleReset(state, action) {
      state.isOpenListCamera = false;
      state.listCameraStream = [];
      state.listProvince = [];
      state.dataCameraWithProvince = [];
      state.dataPreviewCamWithID = null;
      state.actionCamera = "";
      state.randomRender = 0;
      state.selectedCamera = 0;
      state.listLayoutUpdateCamera = [];
      state.toolBox = [];
      state.minimize = 0;
      state.idCameraSelect = 0;
      state.listLayoutCamera = [];
      state.column = 1;
      state.row = 1;
    },
    handleRightClick(state, action) {
      const { x, y, isShowMenu } = action.payload;
      state.top = x;
      state.right = y;
      state.isShowMenu = isShowMenu;
    },
    handleSwap(state, action) {
      const { idCameraSelect, idCameraSwap, layout } = action.payload;
      const itemCameraSelect = layout.filter(
        (item) => item.id === idCameraSelect
      );
      const itemCameraSwap = layout.filter((item) => item.id === idCameraSwap);
      // if (itemCameraSelect !== itemCameraSwap) {
      //   itemCameraSelect.url = itemCameraSwap;
      //   itemCameraSwap.url = itemCameraSelect;
      // }
    },
    handleAddPolygon(state, action) {
      const item = action.payload;
      state.listPolygon.push(item);
    },
    handleSelectZoneAI(state, action) {
      state.pointsPolygon = action.payload.polygon;
    },
    handleDeletePolygon(state, action) {
      const { listPolygon, idPolygon } = action.payload;
      state.listPolygon = listPolygon.filter(
        (item) => item.polygon.id !== idPolygon
      );
    },
    handleUpdatePolygon(state, action) {
      const { payload, listPolygon } = action.payload;
      const updatedListPolygon = listPolygon.map((item) => {
        if (item.polygon.id === payload.polygon.id) {
          return payload;
        }
        return item;
      });
      state.listPolygon = updatedListPolygon;
    },
  },
});

export const {
  handleUpdateCamera,
  removeItemById,
  handleAddCameraLayout,
  handleStopAll,
  handleCameraById,
  handleCheckGrid,
  getSizeGrid,
  getOpenList,
  getListCameraPreview,
  getListProvince,
  getCameraWithProvince,
  getPreviewCameWithID,
  changeCameraStatus,
  randomRender,
  resetgetListCameraPreview,
  handleAddToolBox,
  handleRemoveToolBox,
  handleMinimize,
  handleRotate,
  handleChangeID,
  handleDragItem,
  handleWarning,
  warningItemById,
  handleAddNewGroup,
  handleDataSearch,
  handleGetGroup,
  handleGetCamera,
  handleAddNewLayout,
  handleSelectLayout,
  handleSelect,
  handleChangeLayout,
  handleReset,
  handleActiveCamera,
  handleSwap,
  handleAddPolygon,
  handleSelectZoneAI,
  handleUpdatePolygon,
  handleDeletePolygon,
} = liveSlice.actions;

export const selectListProvince = (state) => state.live.listProvince;
export const selectSizeGrid = (state) => state.live.isChangeGird;
export const selectIsOpenList = (state) => state.live.isOpenListCamera;
export const selectListCameraPreview = (state) => state.live.listCameraPreview;
export const selectListCameraStream = (state) => state.live.listCameraStream;
export const selectDataCameraWithProvince = (state) =>
  state.live.dataCameraWithProvince;
export const selectDataCameraWithID = (state) =>
  state.live.dataPreviewCamWithID;
export const selectCameraStatus = (state) => state.live.actionCamera;
export const selectRandom = (state) => state.live.randomRender;
export const selectCameraById = (state) => state.live.selectedCamera;
export const selectListCameraLayout = (state) => state.live.listLayoutCamera;
export const selectToolBox = (state) => state.live.toolBox;
export const selectMinimize = (state) => state.live.minimize;
export const selectDeg = (state) => state.live.deg;
export const selectRotate = (state) => state.live.idCameraSelect;
export const selectItemDrag = (state) => state.live.itemDrag;
export const selectWarning = (state) => state.live.isWarning;
export const selectDataSearchGroup = (state) => state.live.dataSearch;
export const selectGroup = (state) => state.live.group;
export const selectCameraNonAddress = (state) => state.live.cameraNonAddress;
export const selectListLayout = (state) => state.live.layout;
export const selectDrag = (state) => state.live.isDragActive;
export const selectRow = (state) => state.live.row;
export const selectColum = (state) => state.live.column;
export const selectActiveCamera = (state) => state.live.idCameraActive;
export const selectPolygon = (state) => state.live.listPolygon;
export const pointPolygon = (state) => state.live.pointsPolygon;
export const isChangePointPolygon = (state) => state.live.isChangePointPolygon;

export default liveSlice.reducer;
