import { LoadingOutlined } from "@ant-design/icons";
import { imagesHeader } from "@common/images/image";
import HlsPlayer from "@components/live/hls-player";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const CustomViewStream = ({
  url,
  id,
  keyChange,
  callBackOpenCamera,
  controls,
}) => {
  const dispatch = useDispatch();
  const [urls, setUrls] = useState();
  const [status, setStatus] = useState(true);
  const videoRef = useRef(null);
  const divRef = useRef(null);

  useEffect(() => {
    startStream();
  }, [url]);

  const startStream = () => {
    setUrls();
    const callBack = (data) => {
      if (data) {
        setUrls(`${data[0].urlRestream}`);
        dispatch({
          type: TYPE_ACTION.MAP.CHANGE_KEY,
          payload: 1,
        });
      }
    };
    const errorBack = (data) => {};
    dispatch({
      type: TYPE_ACTION.MAP.GET_PREVIEW,
      payload: { url, callBack, errorBack },
    });
  };
  const stopStream = (url) => {
    if (url) {
      const callBack = (data) => {};
      dispatch({
        type: TYPE_ACTION.LIVE.STOP_STREAM,
        payload: { id: url, callBack },
      });
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setUrls();
        stopStream(url);
      }
    };

    document.addEventListener("click", handleOutsideClick, { capture: true });

    return () => {
      document.removeEventListener("click", handleOutsideClick, {
        capture: true,
      });
    };
  }, []);
  return (
    <>
      {
        <div
          className="w-full h-full relative flex justify-center items-center"
          style={{ background: colors.background }}
        >
          {urls !== undefined ? (
            <HlsPlayer
              playerRef={videoRef}
              playingSrc={urls}
              idCamera={id}
              controls={controls}
            />
          ) : status ? (
            <div className="flex flex-col justify-center items-center">
              <LoadingOutlined
                style={{
                  fontSize: 40,
                  color: colors.textBase,
                }}
              />
              <span style={{ color: colors.textBase }}>Loading</span>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <img
                src={imagesHeader.exit_active}
                className="h-[18px]"
                alt="#"
              />
              <span style={{ color: colors.redGlobal }}>Disconnected</span>
            </div>
          )}
        </div>
      }
    </>
  );
};
export default CustomViewStream;
