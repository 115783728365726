import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { AutoComplete, ConfigProvider, Input } from "antd";
import colors from "@constants/colors";
import _ from "lodash";
const Search = ({
  onChangeAutoComplete,
  options,
  onSelect
}) => (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 0,
          colorBgBase: colors.bg_input,
          colorBorder: colors.borderColor,
          controlHeight: 35,
          controlOutlineWidth: 0,
          colorPrimary: colors.bg_input,
          colorBorderBg: "red",
          colorBorderSecondary: "red",
          colorPrimaryBorder: "red",
          colorBgElevated: colors.background,
          colorText: colors.textBase,
        },
      }}
    >
      <AutoComplete
        popupClassName="certain-category-search-dropdown"
        placeholder="Tìm tiếm"
        style={{
          width: "100%",
        }}
        onChange={_.debounce((value) => onChangeAutoComplete(value), 600)}
        onSelect={onSelect}
        options={options}
        size="large"
        allowClear
      ></AutoComplete>
    </ConfigProvider>
);
export default Search;
