import colors from "@constants/colors";
import { ConfigProvider, Form, Radio } from "antd";

const RadioCheck = ({
  onChange,
  valueInit,
  title,
  width = "300px",
  space = "240px",
  widthTitle = "50%",
  isVertical = true,
  options,
  name,
}) => {
  return (
    <div className="flex" style={{ width: width }}>
      <div
        className="flex items-center font-[600] py-1"
        style={{ color: colors.textBase, width: { widthTitle } }}
      >
        {title}
      </div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colors.redGlobal,
            colorPrimaryHover: colors.redGlobal,
            colorPrimaryBorder: colors.redGlobal,
            colorPrimaryActive: colors.redGlobal,
            colorBgContainer: colors.textBase,
          },
          components: {
            colorBorder: colors.textBase,
          },
        }}
      >
        <Form.Item name={name}>
          <Radio.Group
            onChange={onChange}
            defaultValue={valueInit}
            className={`${isVertical && "flex"} items-center justify-between`}
            style={{ width: space }}
          >
            {options?.map((item, index) => {
              return (
                <Radio
                  key={index}
                  value={item}
                  style={{ color: colors.textBase }}
                >
                  {item}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </ConfigProvider>
    </div>
  );
};

export default RadioCheck;
