import CustomButtonCommon from "@common/button/custom-button";
import CustomInput from "@common/input/custom-input";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { Form, Tabs } from "antd";
import { useRef } from "react";
import { MdCamera } from "react-icons/md";
import { useDispatch } from "react-redux";

const Address = ({ handleCancel }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const onFinish = () => {
    formRef.current.validateFields().then((values) => {
      form.validateFields().then(async (values) => {
        const obj = { ...values };
        let item = {
          url: obj.url,
        };
        const callBack = async (data) => {
          form.resetFields();
        };
        dispatch({
          type: TYPE_ACTION.LIVE.CREATE_CAMERA,
          payload: { body: item, callBack },
        });
      });
    });
  };
  return (
    <Form form={form} ref={formRef}>
      <div
        className="min-h-[600px] w-full bg-white border"
        style={{ background: colors.backgroundSide }}
      >
        <div
          className="grid grid-cols-2 h-[180px] px-2 pt-5"
          style={{ background: colors.background }}
        >
          <div className="col-span-1">
            <CustomInput
              name={"url"}
              title={"Địa chỉ"}
              width="200px"
              placeholder={"IP / RTSP Link"}
            />
          </div>
          <div className="col-span-1">
            <CustomInput title={"Tài khoản"} width="200px" />
          </div>
          <div className="col-span-1">
            <CustomInput title={"Cổng"} width="90px" placeholder={"554"} />
          </div>
          <div className="col-span-1">
            <CustomInput title={"Mật khẩu"} width="200px" />
          </div>
          <CustomButtonCommon
            text={"Tìm kiếm"}
            backgroundColor={colors.redGlobal}
            style={{ paddingLeft: "6px", paddingRight: "6px" }}
          />
        </div>
        <div>
          <div className="flex justify-center items-center min-h-[420px] w-full">
            <MdCamera
              className="text-[130px]"
              style={{ color: colors.borderColor }}
            />
          </div>
        </div>
        <div className="flex justify-end pb-2 pr-2">
          <div className="pr-2">
            <CustomButtonCommon
              text={"Lưu"}
              width="100px"
              backgroundColor={colors.redGlobal}
              htmlType={"submit"}
              onClick={() => {
                onFinish();
                handleCancel();
              }}
            />
          </div>
          <CustomButtonCommon
            text={"Hủy"}
            width="86px"
            onClick={() => {
              handleCancel();
              form.resetFields();
            }}
          />
        </div>
      </div>
    </Form>
  );
};

const Scan = ({ handleCancel }) => {
  return (
    <div
      className="min-h-[600px] w-full bg-white border"
      style={{ background: colors.backgroundSide }}
    >
      <div
        className="grid grid-cols-2 h-[230px] px-2 pt-5"
        style={{ background: colors.background }}
      >
        <div className="col-span-1">
          <CustomInput title={"Start IP"} width="200px" placeholder={"-"} />
        </div>
        <div className="col-span-1">
          <CustomInput title={"Tài khoản"} width="200px" />
        </div>
        <div className="col-span-1">
          <CustomInput title={"End IP"} width="200px" placeholder={"-"} />
        </div>
        <div className="col-span-1">
          <CustomInput title={"Mật khẩu"} width="200px" />
        </div>
        <div className="col-span-2">
          <CustomInput title={"Cổng"} width="90px" placeholder={"554"} />
        </div>
        <CustomButtonCommon
          text={"Quét"}
          backgroundColor={colors.redGlobal}
          width="100px"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        />
      </div>
      <div>
        <div className="flex justify-center items-center min-h-[370px] w-full">
          <MdCamera
            className="text-[130px]"
            style={{ color: colors.borderColor }}
          />
        </div>
      </div>
      <div className="flex justify-end pb-2 pr-2">
        <div className="pr-2">
          <CustomButtonCommon
            text={"Lưu"}
            width="100px"
            backgroundColor={colors.redGlobal}
            htmlType={"submit"}
            onClick={() => {
              handleCancel();
            }}
          />
        </div>
        <CustomButtonCommon
          text={"Hủy"}
          width="86px"
          onClick={() => {
            handleCancel();
          }}
        />
      </div>
    </div>
  );
};

const CreateModalCamera = ({ handleCancel }) => {
  const onChange = (key) => {};
  const items = [
    {
      key: "1",
      label: <p className="ml-2">Địa chỉ đã biết</p>,
      children: (
        <Address
          handleCancel={() => {
            handleCancel();
          }}
        />
      ),
    },
    {
      key: "2",
      label: <p>Quét</p>,
      children: (
        <Scan
          handleCancel={() => {
            handleCancel();
          }}
        />
      ),
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
};
export default CreateModalCamera;
