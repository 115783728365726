import { selectToolBox } from "@redux/slice/live-slice";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import ToolBoxItem from "./tool-box";
import { useState } from "react";

const ToolBox = ({ isShowToolBox, setIsShowToolBox }) => {
  const [isShowHeader, setIsShowHeader] = useState("");
  const ListToolBoxItem = useSelector(selectToolBox);
  return (
    <>
      {isShowToolBox && (
        <div
          className={`scroll_default relative bg-[#36353527] ${isShowHeader}`}
          style={{
            width: "100%",
            zIndex: 999,
          }}
        >
          <div className="flex">
            {ListToolBoxItem?.map((item) => (
              <ToolBoxItem key={item.i} item={item} />
            ))}
          </div>
        </div>
      )}
      <div
        className="h-[20px] flex items-center justify-center w-max p-2 rounded-[4px] ml-[50%]"
        style={{ backgroundColor: "#363535" }}
      >
        {isShowToolBox ? (
          <RiArrowUpSFill
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setIsShowHeader("scale-down-ver-top");
              setIsShowToolBox(false);
            }}
          />
        ) : (
          <RiArrowDownSFill
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setIsShowHeader("scale-up-ver-top");
              setIsShowToolBox(true);
            }}
          />
        )}
      </div>
    </>
  );
};

export default ToolBox;
