import CustomFooter from "@common/footer/custom-footer";
import CustomInput from "@common/input/custom-input";

const ChanelName = () => {
    return ( 
        <div className="h-[90vh]">
        <div className="w-full h-[87vh] px-2 pt-3 overflow-auto scroll_default">
            <div className="flex flex-wrap max-w-[900px] justify-around">
                <CustomInput width="280px" title={'CAM 1'} defaultValue={'Nhà bè'}/>
                <CustomInput width="280px" title={'CAM 2'} defaultValue={"Cầu Nhật Tân"}/>
                <CustomInput width="280px" title={'CAM 3'} defaultValue={'Nhà bé'}/>
                <CustomInput width="280px" title={'CAM 4'} defaultValue={"Nhà hát lớn"}/>
                <CustomInput width="280px" title={'CAM 5'}/>
                <CustomInput width="280px" title={'CAM 6'}/>
                <CustomInput width="280px" title={'CAM 7'}/>
                <CustomInput width="280px" title={'CAM 8'}/>
                <CustomInput width="280px" title={'CAM 9'}/>
                <CustomInput width="280px" title={'CAM 10'}/>
                <CustomInput width="280px" title={'CAM 11'}/>
                <CustomInput width="280px" title={'CAM 12'}/>
                <CustomInput width="280px" title={'CAM 13'}/>
                <CustomInput width="280px" title={'CAM 14'}/>
                <CustomInput width="280px" title={'CAM 15'}/>
                <CustomInput width="280px" title={'CAM 16'}/>
                <CustomInput width="280px" title={'CAM 17'}/>
                <CustomInput width="280px" title={'CAM 18'}/>
                <CustomInput width="280px" title={'CAM 19'}/>
                <CustomInput width="280px" title={'CAM 20'}/>
                <CustomInput width="280px" title={'CAM 21'}/>
                <CustomInput width="280px" title={'CAM 22'}/>
                <CustomInput width="280px" title={'CAM 23'}/>
                <CustomInput width="280px" title={'CAM 24'}/>
            </div>
        </div>
          <CustomFooter />
      </div>
     );
}
 
export default ChanelName;