const Warning = () => {
  return (
    <div
      className="w-full h-full bg-transparent relative ping"
      style={{
        boxShadow: "10px 6px 10px 6px #ffcc00",
        border: "3px solid #ffcc00",
        zIndex: 1,
      }}
    ></div>
  );
};

export default Warning;
