import HeaderVideo from "@components/live/NX/header-video";
import { removeItemById } from "@redux/slice/live-slice";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch } from "react-redux";

const IframeCustom = ({ item, iframeRef, hoverID }) => {
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  return (
    <div className="h-full">
      {hoverID === item.id && (
        <HeaderVideo
          idCamera={item.id}
          removeItemById={() =>
            dispatch(removeItemById({ idCamera: item.id, status: "remove" }))
          }
          handleFullScreen={() => {
            handle.enter();
          }}
          item={item}
          isFrame
        />
      )}
      <div className="h-full pt-[6%]">
        <FullScreen handle={handle} className="h-full">
          <iframe
            ref={iframeRef}
            width="100%"
            height="94%"
            src={item.url}
            frameborder="0"
            allow="autoplay; encrypted-media;X-Frame-Options"
            allowFullScreen={true}
            title="video"
            allow-popups="true"
            className="target"
          />
        </FullScreen>
      </div>
    </div>
  );
};

export default IframeCustom;
