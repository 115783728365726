const TYPE_ACTION = {
  CAMERA: {
    GET_LIST_DEVICE: "GET_LIST_DEVICE",
  },
  LIVE: {
    GET_LIST_PROVINCE: "GET_LIST_PROVINCE",
    CHANGE_GRID: "CHANGE_GRID",
    OPEN_LIST: "OPEN_LIST",
    GET_LIST_CAMERA_PREVIEW: "GET_LIST_CAMERA_PREVIEW",
    GET_LIST_GROUP: "GET_LIST_GROUP",
    GET_DATA_CAMERA_WITH_PROVINCE: "GET_DATA_CAMERA_WITH_PROVINCE",
    GET_PREVIEW_WITH_ID: "GET_PREVIEW_WITH_ID",
    GET_DISTRICT_LIVE: "GET_DISTRICT_LIVE",
    GET_WARD_LIVE: "GET_WARD_LIVE",
    GET_DEVICE_LIVE: "GET_DEVICE_LIVE",
    GET_ALL_CAMERA_STREAM: "GET_ALL_CAMERA_STREAM",
    STOP_STREAM: "STOP_STREAM",
    STOP_ALL_STREAM: "STOP_ALL_STREAM",
    GET_LIVE_GROUP: "GET_LIVE_GROUP",
    CREATE_GROUP: "CREATE_GROUP",
    UPDATE_GROUP: "UPDATE_GROUP",
    DELETE_GROUP: "DELETE_GROUP",
    TREE_GROUP: "TREE_GROUP",
    SEARCH_CAMERA: "SEARCH_CAMERA",
    CREATE_CAMERA: "CREATE_CAMERA",
    GET_CAMERA_NON_ADDRESS: "GET_CAMERA_NON_ADDRESS",
    DELETE_CAMERA: "DELETE_CAMERA",
  },
  MAP: {
    GET_LOCATION_CAMERA: "GET_LOCATION_CAMERA",
    GET_ADDRESS: "GET_ADDRESS",
    GET_PROVINCE: "GET_PROVINCE",
    GET_WARD: "GET_WARD",
    GET_DISTRICT: "GET_DISTRICT",
    GET_CODE: "GET_CODE",
    GET_TOTAL: "GET_TOTAL",
    GET_RECORDER: "GET_RECORDER",
    GET_ADDRESS_DRAW_CIRCLE: "GET_ADDRESS_DRAW_CIRCLE",
    GET_ADDRESS_DRAW_POLYGON: "GET_ADDRESS_DRAW_POLYGON",
    GET_PREVIEW: "GET_PREVIEW",
    CLOSE_PRVIEW: "CLOSE_PRVIEW",
    CHANGE_KEY: "CHANGE_KEY",
    GET_DATA_CAMERA_GROUP: "GET_DATA_CAMERA_GROUP",
    CLOSE_GROUP: "CLOSE_GROUP",
  },
  MAPSINGLE:{
    SEARCH_CAMERA_MAP_SINGLE: "SEARCH_CAMERA_MAP_SINGLE"
  },
  ACTION: {
    IS_TOGGLE: "IS_TOGGLE",
    IS_SIDEBAR: "IS_SIDEBAR",
    IS_ACTION_CAMERA: "IS_ACTION_CAMERA",
    CHANGE_KEY_STREAM: "CHANGE_KEY_STREAM",
  },
  AUTH: {
    SIGN_IN: "SIGN_IN",
  },
  EVENT: {
    GET_ALL_EVENT: "GET_ALL_EVENT",
    GET_COLLAPSE: "GET_COLLAPSE",
  },
  PROFILE: {
    GET_PROFILE: "GET_PROFILE",
    GET_EVENT_PROFILE: "GET_EVENT_PROFILE",
  },
  VEHICLE: {
    GET_VEHICLE: "GET_VEHICLE",
  },
  VIEW_EVENT: {
    GET_VIEW_EVENT: "GET_VIEW_EVENT",
    GET_COLLAPSE: "GET_COLLAPSE",
  },
  GROUP: {
    GET_GROUP: "GET_GROUP",
  },
};
export default TYPE_ACTION;
