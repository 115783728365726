import TYPE_ACTION from "@constants/action";
import { selectDataLocationCam } from "@redux/slice/map-slice";
import { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import colors from "@constants/colors";
import { TileLayer, useMap, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import LocationCamera from "./location-camera";
import HoangSaMap from "./hoang-sa-map";
// import { jsonDataMap } from "./dataMap/geoDataAll";

const CustomMap = ({ mapRef, attribution, urlMap }) => {
  const [zoom, setZoom] = useState();
  const dataCamLocation = useSelector(selectDataLocationCam);
  const dispatch = useDispatch();
  const map = useMap();
  const markerClusterGroupRef = useRef(null);
  const createClusterCustomIcon = function (cluster) {
    return new L.DivIcon({
      html: `
      <div
        style="
          width: 50px; 
          height: 50px; 
          border-radius: 50%; 
          background: ${colors.redGlobal}; 
          border: 1px solid ${colors.background};
          display: flex; 
          align-items: center; 
          justify-content: center;">
          <div 
            style="color:${colors.textBase};
            height: 40px; 
            width: 40px;
            border-radius: 50%;
            background:  ${colors.background}; 
            border: 1px solid ${colors.background}; 
            display: flex; 
            align-items: center; 
            justify-content: center;">
              ${cluster.getChildCount()}
          </div>
        </div>`,
      className: "custom-marker-cluster",
      iconSize: L.point(33, 33, true),
    });
  };

  const handleSendDataCameraGroup = (e, i) => {
    const zoom = e.layer;
    if (17 <= zoom._zoom <= 20) {
      const position = e.latlng;
      const arr = {
        center: {
          latitude: position.lat,
          longitude: position.lng,
        },
        radius: 0.01,
      };
      const callback = (data) => {
        // console.log("data",data);
      };
      dispatch({
        type: TYPE_ACTION.MAP.GET_DATA_CAMERA_GROUP,
        payload: { body: arr, callback: callback },
      });
    }
  };
  return (
    <>
      <TileLayer attribution={attribution} url={urlMap} maxZoom={20} />
      <MarkerClusterGroup
        ref={markerClusterGroupRef}
        polygonOptions={{
          color: colors.textBase,
          fillColor: colors.textBase,
        }}
        spiderfyDistanceMultiplier={2}
        spiderLegPolylineOptions={{
          color: colors.textBase,
          fillColor: colors.textBase,
        }}
        iconCreateFunction={createClusterCustomIcon}
        spiderfyOnMaxZoom={true}
        maxClusterRadius={8}
        // showCoverageOnHover={false}
        chunkedLoading
        animate={true}
      >
        {dataCamLocation !== null && (
          <LocationCamera
            mapRef={mapRef}
            map={map}
            dataCamLocation={dataCamLocation}
            controls={true}
          />
        )}
      </MarkerClusterGroup>
      {/* {jsonDataMap.map((item, index) => {
        if(hightlight === item.code){
          return <MapHighLine key={index} jsonDataMap={item.data} />;
        }
      })} */}
      <ZoomControl position="topright" />
      <HoangSaMap zoomShow={zoom} />
    </>
  );
};

export default memo(CustomMap);
