import { useEffect, useRef } from "react";
import Hls from "hls.js";
import styled from "styled-components";
import "@style/style-nx.css";
import { useSelector } from "react-redux";
import { selectPolygon } from "@redux/slice/live-slice";
import PolygonSVG from "./polygon/polygon-svg";

const StyledVideo = styled.video`
  width: ${({ width }) => width || "100%"};
  aspect-ratio: ${(props) => props.aspectRatio || "16 / 9"};
  cursor: pointer;
  object-fit: contain;
  &:hover ~ div {
    display: flex;
  }
`;

const HlsPlayer = ({
  playerRef,
  playingSrc,
  posterSrc,
  callBackError,
  idCamera,
  controls = false,
  deg,
  settingAi,
}) => {
  const hlsRef = useRef(null);
  const listPolygon = useSelector(selectPolygon);

  useEffect(() => {
    let hls;
    const _initPlayer = () => {
      if (hls) {
        hls.destroy();
      }
      hls = new Hls({
        enableWorker: true,
        startLevel: -1,
      });

      if (playerRef.current) {
        hls.attachMedia(playerRef.current);
      }

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(playingSrc);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // setHlsInstance logic
        });
        hls.on(Hls.Events.FRAG_PARSING_METADATA, (_event, data) => {
          console.log("Data", { _event, data });
        });
      });

      hls.on(Hls.Events.ERROR, (_event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              callBackError(idCamera);
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              callBackError(idCamera);
              hls.recoverMediaError();
              break;
            default:
              callBackError(idCamera);
              _initPlayer();
              break;
          }
        }
      });

      hlsRef.current = hls;
    };

    if (Hls.isSupported()) {
      _initPlayer();
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [playingSrc, playerRef]);

  let width;
  let height;

  return (
    <div className="flex">
      <StyledVideo
        className={`rotate-${deg}-cw`}
        ref={playerRef}
        src={playingSrc}
        autoPlay={true}
        poster={posterSrc}
        controls={controls}
        aspectRatio="16 / 9"
      />
      {listPolygon &&
        !settingAi &&
        listPolygon
          .filter((item) => item.polygon.idCamera === idCamera && item.status)
          .map((item) => (
            <PolygonSVG
              key={item.id}
              name={item.nameZone}
              point={item.polygon.point}
              colors={item.polygon.colors}
              width={width}
              height={height}
            />
          ))}
    </div>
  );
};

export default HlsPlayer;
