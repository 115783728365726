import { ConfigProvider, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { selectCameraNonAddress } from "@redux/slice/live-slice";

const CustomTreeFile = ({
  initTreeData,
  onSelect,
  data = [],
  titleRender,
  showIcon = true,
}) => {

  const dispatch = useDispatch();
  const dataGroup = useSelector(selectCameraNonAddress)
  const camRef = useRef({
    id: null,
    name: null,
    description: null,
    parentGroupId: null,
    childGroupIds: null,
    recorderIds: null,
    cameraIds: null,
  });

  const updatedChildren = dataGroup?.results?.map((item, i) => ({
    title: item.name,
    key: `${item.id}-${i}`,
    camera: true,
    isLeaf: true,
    url: item.urlMainstream,
    status: item.status,
    id: item.id,
  }));

  const treeData = []

  
  useEffect(() => {
    dispatch({
      type: TYPE_ACTION.LIVE.GET_CAMERA_NON_ADDRESS,
      payload: { body: camRef.current },
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "rgba(16, 16, 22, 0)",
          controlItemBgActive: colors.transparent,
          lineHeight: 2,
          colorText: colors.textBase,
          colorPrimary: colors.redGlobal,
          colorBorder: colors.borderColor,
          paddingXS: 5,
        },
      }}
    >
      <Tree
        checkable ={false}
        treeData={updatedChildren}
        showIcon={showIcon}
        switcherIcon={
          <DownOutlined size={24} style={{ height: "24px", width: "24px" }} />
        }
        showLine={true}
        onSelect={onSelect}
        titleRender={titleRender}
      />
    </ConfigProvider>
  );
};
export default CustomTreeFile;
