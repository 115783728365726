import CustomButtonCommon from "@common/button/custom-button";
import CustomInput from "@common/input/custom-input";
import colors from "@constants/colors";
import { Form } from "antd";
import { useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import CustomSelect from "@common/select/custom-select";
import { selectDataSearchGroup, selectGroup } from "@redux/slice/live-slice";

const CreateModalGroup = ({ handleCancel }) => {
  const dataSearch = useSelector(selectDataSearchGroup);
  const dataGroup = useSelector(selectGroup);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const searchCameraRef = useRef({
    page_index: 0,
    page_size: 100,
    q: "",
  });
  const camRef = useRef({
    id: null,
    name: null,
    description: null,
    parentGroupId: null,
    childGroupIds: null,
    recorderIds: null,
    cameraIds: null,
  });

  const onFinish = () => {
    formRef.current.validateFields().then((values) => {
      form.validateFields().then(async (values) => {
        const obj = { ...values };
        let item = {
          name: obj.name,
          description: obj.description,
          cameraIds: obj.cameraIds?.map((item) => Number(item)),
          parentGroupId: obj.parentGroupId,
        };
        const callBack = async (data) => {
          dispatch({
            type: TYPE_ACTION.LIVE.GET_LIVE_GROUP,
            payload: { body: camRef.current },
            });
          form.resetFields();
        };
        dispatch({
          type: TYPE_ACTION.LIVE.CREATE_GROUP,
          payload: { body: item, callBack },
        });
      });
    });
  };
  const handleSearchCamera = (camera) => {
    const callback = (data) => {};
    searchCameraRef.current.q = camera;
    dispatch({
      type: TYPE_ACTION.LIVE.SEARCH_CAMERA,
      payload: { body: searchCameraRef.current, callback },
    });
  };
  const handleSelectCamera = (camera, info) => {
    console.log(info);
  };
  const onChangeAutoComplete = (value) => {
    handleSearchCamera(value);
  };

  useEffect(() => {
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIVE_GROUP,
      payload: { body: camRef.current },
    });
  }, []);

  const handleSelect = (data) => {
    let arr = [];
    data.forEach((item) => {
      arr.push({
        label: item.title,
        info: item,
        value: `${item.id}`,
      });
    });
    return arr;
  };

  return (
    <Form form={form} ref={formRef}>
      <div
        className="min-h-[120px] w-full justify-between items-center pt-2 px-4 border"
        style={{ background: colors.backgroundSide }}
      >
        <CustomInput name={"name"} title={"Tên nhóm"} width="200px" />
        <CustomInput name={"description"} title={"Miêu tả"} width="200px" />
        <CustomSelect
          name={"parentGroupId"}
          title={"Chọn Group"}
          width="200px"
          option={handleSelect(dataGroup)}
          background={colors.background_form}
        />
        <CustomSelect
          name={"cameraIds"}
          title={"Thêm Camera"}
          mode={"multiple"}
          showSearch
          placeholder={"Nhập ip để tìm kiếm"}
          width="200px"
          onSelect={() => handleSelectCamera}
          onSearch={_.debounce((value) => onChangeAutoComplete(value), 600)}
          option={dataSearch}
          background={colors.background_form}
        />
        <div className="flex justify-end pb-2">
          <div className="pr-2">
            <CustomButtonCommon
              text={"Lưu"}
              width="100px"
              backgroundColor={colors.redGlobal}
              htmlType={"submit"}
              onClick={() => {
                onFinish();
                handleCancel();
              }}
            />
          </div>
          <CustomButtonCommon
            text={"Hủy"}
            width="86px"
            onClick={(e) => {
              handleCancel();
              form.resetFields();
            }}
          />
        </div>
      </div>
    </Form>
  );
};
export default CreateModalGroup;
