let url = "/assets/image"
let urlIconSidebaer = "/assets/icons/sidemenu"
let urlIconHeader = "/assets/icons/header"
let urlIconGrid = "/assets/icons/grid"
let urlIconGroup = "/assets/icons/group"
let urlIconPtz = "/assets/icons/ptz"
let urlIconCamera = "/assets/icons/camera"
let urlIconOther = "/assets/icons/other"


export const imagesSibar={
    dashboard_default:`${urlIconSidebaer}/dashboard-default.svg`,
    dashboard_active:`${urlIconSidebaer}/dashboard-active.svg`,
    scanning_default:`${urlIconSidebaer}/scanning-default.svg`,
    scanning_active:`${urlIconSidebaer}/scanning-active.svg`,
    live_default:`${urlIconSidebaer}/live-default.svg`,
    live_active:`${urlIconSidebaer}/live-active.svg`,
    playback_default:`${urlIconSidebaer}/playback-default.svg`,
    playback_active:`${urlIconSidebaer}/playback-active.svg`,
    camera_default:`${urlIconSidebaer}/camera-default.svg`,
    camera_active:`${urlIconSidebaer}/camera-active.svg`,
    device_default:`${urlIconSidebaer}/device-default.svg`,
    device_active:`${urlIconSidebaer}/device-active.svg`,
    user_default:`${urlIconSidebaer}/user-default.svg`,
    user_active:`${urlIconSidebaer}/user-active.svg`,
    log_default:`${urlIconSidebaer}/log-default.svg`,
    log_active:`${urlIconSidebaer}/log-active.svg`,
    setting_default:`${urlIconSidebaer}/setting-default.svg`,
    setting_active:`${urlIconSidebaer}/setting-active.svg`,
    expand_left:`${urlIconSidebaer}/expand-left.svg`,
    expand_right:`${urlIconSidebaer}/expand-right.svg`,
    logo:`${urlIconSidebaer}/logo.svg`,
}
export const imagesHeader={
    filter_default:`${urlIconHeader}/filter-default.svg`,
    filter_hover:`${urlIconHeader}/filter-hover.svg`,
    filter_active:`${urlIconHeader}/filter-active.svg`,
    grid_default:`${urlIconHeader}/grid-default.svg`,
    grid_active:`${urlIconHeader}/grid-active.svg`,
    stream_default:`${urlIconHeader}/stream-default.svg`,
    stream_active:`${urlIconHeader}/stream-active.svg`,
    info_default:`${urlIconHeader}/info-default.svg`,
    info_active:`${urlIconHeader}/info-active.svg`,
    playback_default:`${urlIconHeader}/playback-default.svg`,
    playback_active:`${urlIconHeader}/playback-active.svg`,
    audit_default:`${urlIconHeader}/audit-default.svg`,
    audit_active:`${urlIconHeader}/audit-active.svg`,
    full_screen_default:`${urlIconHeader}/full-screen-default.svg`,
    full_screen_active:`${urlIconHeader}/full-screen-active.svg`,
    config_default:`${urlIconHeader}/config-default.svg`,
    config_active:`${urlIconHeader}/config-active.svg`,
    delete_default:`${urlIconHeader}/delete-default.svg`,
    delete_active:`${urlIconHeader}/delete-active.svg`,
    exit_default:`${urlIconHeader}/exit-default.svg`,
    exit_active:`${urlIconHeader}/exit-active.svg`,
}

export const imagesGrid={
    grid_1:`${urlIconGrid}/grid-1.png`,
    grid_4:`${urlIconGrid}/grid-4.png`,
    grid_9:`${urlIconGrid}/grid-9.png`,
    grid_16:`${urlIconGrid}/grid-16.png`,
    grid_25:`${urlIconGrid}/grid-25.png`,
    grid_36:`${urlIconGrid}/grid-36.png`,
    grid_64:`${urlIconGrid}/grid-64.png`,
    add_camera:`${urlIconGrid}/add_camera.svg`,
    grid_all:`${urlIconGrid}/grid-all.svg`,
    capture:`${urlIconGrid}/capture.svg`,
    download:`${urlIconGrid}/download.svg`,
    exit:`${urlIconGrid}/exit.svg`,
    full_screen:`${urlIconGrid}/full_screen.svg`,
    play:`${urlIconGrid}/play.svg`,
    rewind:`${urlIconGrid}/rewind.svg`,
    share:`${urlIconGrid}/share.svg`,
    speed:`${urlIconGrid}/speed.svg`,
    tassel:`${urlIconGrid}/tassel.svg`,
    trim_video:`${urlIconGrid}/trim_video.svg`,
    volume:`${urlIconGrid}/volume.svg`,
}

export const imagesGroup={
    camera_connected:`${urlIconGroup}/camera-connected.svg`,
    camera_disconnected:`${urlIconGroup}/camera-disconnected.svg`,
    camera_default:`${urlIconGroup}/camera-default.svg`,
    folder_default:`${urlIconGroup}/folder-default.svg`,
    folder_active:`${urlIconGroup}/folder-active.svg`,
    arrow_default:`${urlIconGroup}/arrow-default.svg`,
    list_default:`${urlIconGroup}/list-default.svg`,
    list_active:`${urlIconGroup}/list-active.svg`,
    edit_default:`${urlIconGroup}/edit-default.svg`,
    delete_default:`${urlIconGroup}/delete-default.svg`,
    add_default:`${urlIconGroup}/add-default.svg`,
    info_default:`${urlIconGroup}/info-default.svg`,
    info_active:`${urlIconGroup}/info-active.svg`,
    audit_default:`${urlIconGroup}/audit-default.svg`,
    audit_active:`${urlIconGroup}/audit-active.svg`,
}
export const images ={
    background_login :`${url}/background_login.svg`,
    logo : `${url}/logo_hawk.svg`,
    calendar : `${url}/calendarA.png`,
    noImage: `${url}/image_not_found.png`,
}
export const imagesPTZ={
    top_left:`${urlIconPtz}/top_left.svg`,
    top:`${urlIconPtz}/top.svg`,
    top_right:`${urlIconPtz}/top_right.svg`,
    left:`${urlIconPtz}/left.svg`,
    around:`${urlIconPtz}/around.svg`,
    right:`${urlIconPtz}/right.svg`,
    bottom_left:`${urlIconPtz}/bottom_left.svg`,
    bottom:`${urlIconPtz}/bottom.svg`,
    bottom_right:`${urlIconPtz}/bottom_right.svg`,
    zoom_in:`${urlIconPtz}/zoom_in.svg`,
    zoom_out:`${urlIconPtz}/zoom_out.svg`,
    irs_in:`${urlIconPtz}/irs_in.svg`,
    irs_out:`${urlIconPtz}/irs_out.svg`,
    focus_in:`${urlIconPtz}/focus_in.svg`,
    focus_out:`${urlIconPtz}/focus_out.svg`,
    sun_a:`${urlIconPtz}/sun_a.svg`,
    sun_b:`${urlIconPtz}/sun_b.svg`,
    sun_c:`${urlIconPtz}/sun_c.svg`,
    sun_d:`${urlIconPtz}/sun_d.svg`,
    sun_e:`${urlIconPtz}/sun_e.svg`,
}

export const imagesCamera ={
    camera_connected :`${urlIconCamera}/camera_connected.svg`,
    camera_disconnected:`${urlIconCamera}/camera_disconnected.svg`,
    view_default:`${urlIconCamera}/view_default.svg`,
    edit_default:`${urlIconCamera}/edit_default.svg`,
    delete_default:`${urlIconCamera}/delete_default.svg`,
}

export const imagesOther ={
    change_map :`${urlIconOther}/change_map.svg`,
    NVR :`${urlIconOther}/NVR.svg`,
}