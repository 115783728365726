import { imagesCamera } from "@common/images/image";
import { Marker, Popup } from "react-leaflet";
import styled from "styled-components";
import L from "leaflet";
import CustomViewStream from "./view-stream";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import TYPE_ACTION from "@constants/action";
import { useDrag } from "react-dnd";

const StyledPop = styled(Popup)`
  border-radius: 0;
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    width: 240px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;
const LocationCamera = ({ map, dataCamLocation, controls }) => {
  const arrLocation = [];
  dataCamLocation?.forEach((item) => {
    arrLocation.push({
      id: item.id,
      latLng: item.addressDTO,
      status: item.status,
    });
  });
  var iconCamConnected = L.icon({
    iconUrl: imagesCamera.camera_connected,
    iconSize: [20, 20],
  });

  var iconCamDisconnected = L.icon({
    iconUrl: imagesCamera.camera_disconnected,
    iconSize: [20, 20],
  });

  const dispatch = useDispatch();

  const [showStyledPop, setShowStyledPop] = useState(true);

  const handleStopStream = (id) => {
    const callBack = (data) => {};
    dispatch({
      type: TYPE_ACTION.LIVE.STOP_STREAM,
      payload: { id: id, callBack },
    });
  };
  const sendMessageToParent = (item) => {
    const message = { type: "MAP_EVENT", payload: item };
    window.parent.postMessage(message, "*");
  };

  const ContentCamera = ({ item }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          type: "single",
          url: item.id,
        };
        sendMessageToParent(dragItem);
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        item: monitor.getItem(),
      }),
      end: () => {
        const channel = new BroadcastChannel("my-channel");
        // Gửi object data qua BroadcastChannel
        const dragItem = {
          type: "single",
          url: item.id,
        };
        channel.postMessage(dragItem);
      },
    }));
    return (
      <div
        ref={drag}
        className="h-[135px] w-[240px] overflow-hidden cursor-pointer border border-solid border-white"
      >
        <CustomViewStream
          key={item.id}
          url={item.id}
          id={item.id}
          controls={controls}
        />
      </div>
    );
  };

  return (
    <>
      {arrLocation.map((item, index) => {
        return (
          <Marker
            key={item.id}
            icon={item.status ? iconCamConnected : iconCamDisconnected}
            position={[item.latLng.latitude, item.latLng.longitude]}
          >
            {showStyledPop && (
              <StyledPop
                eventHandlers={{
                  remove: async () => {
                    try {
                      // await handleStopStream(item.id);
                      await map.closePopup();
                      await setShowStyledPop(false);
                      setShowStyledPop(true);
                    } catch (error) {
                      console.error("Lỗi khi thực hiện các hàm:", error);
                    }
                  },
                }}
              >
                <ContentCamera item={item} />
              </StyledPop>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default LocationCamera;
