const PolygonSVG = ({ key, name, point, colors, width, height }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      <svg width={`${width}`} height={`${height}`} viewBox={`0 0 1080 608`}>
        <text
          x={`${point[0][0] - 4}`}
          y={`${point[0][1]}`}
          textAnchor="end"
          fontSize={20}
          fontWeight={600}
          fill={`${colors.slice(0, -2)}`}
        >
          {name.toUpperCase()}
        </text>
        <polygon
          points={`${point.map((coord) => coord.join(",")).join(" ")}`}
          fill={colors}
          stroke={`${colors.slice(0, -2)}`}
          strokeWidth={1.5}
        />
      </svg>
    </div>
  );
};

export default PolygonSVG;
