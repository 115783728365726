import colors, { sizes } from "@constants/colors";
import { ConfigProvider, Form, Input, InputNumber } from "antd";

const CustomInputNumber = ({
  title,
  placeholder,
  type,
  name,
  font = "font-semibold",
  width = "100%",
  height = "40px",
  widthText = "150px",
  lineHeight = "10px",
  backgroundColor = colors.background,
  defaultValue,
  isPassWord = false,
  isVertical = false,
  disabled = false,
  required,
  rules,
  htmlType,
  feedback,
  onChange,
  min,
  max
}) => {
  return (
    <>
      <div
        className={`flex flex-col sm:flex-row item-center`}
        style={{
          marginBottom: lineHeight,
          flexDirection: !isVertical ? "row" : "column",
        }}
      >
        <div className={`flex mb-[10px]`} style={{ width: widthText }}>
          <span
            className={`flex items-center text-balance ${font}`}
            style={{ color: colors.textBase }}
          >
            {title}
          </span>
        </div>
        <div>
          <ConfigProvider
            theme={{
              token: {
                colorBgBase: colors.background,
                colorBgContainerDisabled: colors.background,
                colorTextDisabled: colors.text,
                colorPrimaryBg: colors.redGlobal,
                colorBgContainer: backgroundColor,
                colorBgElevated: colors.background_form,
                controlHeight: sizes.heightInput,
                colorTextPlaceholder: colors.textPlaceholder,
                colorBorder: colors.textBase,
                controlItemBgActive: colors.background,
                colorText: colors.textBase,
                controlOutlineWidth: 0,
                colorPrimary: colors.redGlobal,
                colorFillSecondary: colors.background,
                colorTextBase: colors.text,
                borderRadius: 0,
                colorIcon: colors.redGlobal,
              },
            }}
          >
            <Form.Item
              name={name}
              required={required}
              rules={rules}
              htmlType={htmlType}
              hasFeedback={feedback}
            >
              <InputNumber
                disabled={disabled}
                type={type}
                defaultValue={defaultValue}
                name={name}
                placeholder={placeholder}
                style={{ width: width, height: height }}
                min={min}
                max={max}
                onChange={onChange}
              />
            </Form.Item>
          </ConfigProvider>
        </div>
      </div>
    </>
  );
};

export default CustomInputNumber;
