/* eslint-disable array-callback-return */
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { images, imagesHeader } from "@common/images/image";
import HeaderVideo from "@components/live/NX/header-video";
import HlsPlayer from "@components/live/hls-player";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import "@style/style-grid-layout.css";
import { handleActiveCamera, removeItemById } from "@redux/slice/live-slice";
import { ConfigProvider, notification } from "antd";
import { useEffect, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch } from "react-redux";

const CustomViewVideo = ({
  height = "100%",
  width = "100%",
  url,
  index,
  idCamera,
  actionCamera,
  onClick,
  callBackError,
  item,
  showAnimation,
  hoverID,
  nameCamera,
}) => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: <span style={{ color: colors.redGlobal }}>CẢNH BÁO</span>,
      description: "Cảnh báo có sự chuyển động !!!",
      icon: (
        <WarningOutlined
          style={{
            color: colors.redGlobal,
          }}
        />
      ),
      placement: "bottomRight",
      showProgress: true,
      pauseOnHover: false,
    });
  };
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (item.isWarning) {
      openNotification();
      setTimeout(() => {
        handle.enter();
      }, 800);
    }
    handle.exit();
  }, [item.isWarning]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#faad14",
            colorText: colors.textBase,
          },
        }}
      >
        {contextHolder}
      </ConfigProvider>
      <div
        key={index}
        className={
          "flex justify-center cursor-pointer overflow-hidden items-center bg-[#00000033]"
        }
        onClick={onClick}
        style={{
          height: height,
          width: width,
          aspectRatio: "16 / 9",
          // borderColor:
          //   isOver.isOver || selectedCamera === index
          //     ? colors.redGlobal
          //     : colors.textPlaceholder,
          position: "relative",
        }}
        onContextMenu={(e) => {
          dispatch(handleActiveCamera(idCamera));
        }}
      >
        {actionCamera !== "Loading" && hoverID === idCamera && (
          <HeaderVideo
            idCamera={idCamera}
            removeItemById={() =>
              dispatch(removeItemById({ idCamera: idCamera, status: "remove" }))
            }
            handleFullScreen={() => {
              handle.enter();
            }}
            item={item}
            showAnimation={showAnimation}
            handleReset={callBackError}
          />
        )}
        <FullScreen handle={handle}>
          {(() => {
            switch (actionCamera) {
              case "Loading":
                return (
                  <div className="flex justify-center items-center flex-col w-max h-max">
                    <LoadingOutlined
                      style={{
                        fontSize: "100%",
                        color: colors.colorLoading,
                      }}
                    />
                    <span style={{ color: colors.colorLoading }} className="">
                      Loading
                    </span>
                  </div>
                );
              case "Reconnect":
                return (
                  <div className="flex justify-center items-center flex-col w-max h-max loader"></div>
                );
              case "Connect":
                return (
                  url && (
                    <HlsPlayer
                      playerRef={videoRef}
                      playingSrc={url}
                      callBackError={callBackError}
                      idCamera={idCamera}
                      deg={item.deg}
                    />
                  )
                );
              case "Disconnect":
                return (
                  <div className="flex justify-center items-center flex-col">
                    <img
                      className="w-[50%] h-[50%]"
                      src={imagesHeader.exit_active}
                      alt="#"
                      onClick={() => {
                        removeItemById(index);
                        const callBack = (data) => {};
                        dispatch({
                          type: TYPE_ACTION.LIVE.STOP_STREAM,
                          payload: { id: idCamera, callBack },
                        });
                      }}
                    />
                    <span style={{ color: colors.redGlobal }}>
                      Disconnected
                    </span>
                  </div>
                );
              default:
                return (
                  <img alt="#" src={images.logo} style={{ height: "20%" }} />
                );
            }
          })()}
        </FullScreen>
        <div
          className="absolute bottom-1 left-1 w-full h-[24px] flex justify-start items-center"
          style={{
            fontSize: 24,
            color: actionCamera === "Connect" ? "green" : "red",
          }}
        >
          <div
            className={`absolute top-[10px] w-2 h-2 rounded-full ${
              actionCamera === "Connect" ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
          <span className="ml-3" style={{ fontSize: "1rem" }}>
            camera_{nameCamera}
          </span>
        </div>
      </div>
    </>
  );
};

export default CustomViewVideo;
