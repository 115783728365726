import CustomButtonCommon from "@common/button/custom-button";
import CustomInput from "@common/input/custom-input";
import colors from "@constants/colors";
import { handleAddNewLayout, selectColum, selectListCameraLayout, selectRow } from "@redux/slice/live-slice";
import { Form } from "antd";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const SaveLayout = ({handleCancel}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const layout = useSelector(selectListCameraLayout);
  const column = useSelector(selectColum);
  const row = useSelector(selectRow);
    const onFinish = () => {
      formRef.current.validateFields().then((values) => {
        form.validateFields().then(async (values) => {
          dispatch(handleAddNewLayout({listCamera:layout, nameLayout:values, row:row, column:column}))
          form.resetFields();
        });
      });
      };
    return ( 
      <Form form={form} ref={formRef}>
        <div className="justify-between px-3 pt-3 border">
        <CustomInput isVertical name={"name"} title={"Tên Layout"} width="200px" />
        <div className="flex justify-end pb-2 pr-2">
          <div className="pr-2">
            <CustomButtonCommon
              text={"Lưu"}
              width="100px"
              backgroundColor={colors.redGlobal}
              htmlType={"submit"}
              onClick={() => {
                onFinish()
                handleCancel();
              }}
            />
          </div>
          <CustomButtonCommon
            text={"Hủy"}
            width="100px"
            onClick={(e) => {
              handleCancel();
              form.resetFields();
            }}
          />
        </div>
        </div>
      </Form>
     );
}
 
export default SaveLayout;