/* eslint-disable array-callback-return */
/* eslint-disable no-self-compare */
import { createSlice } from "@reduxjs/toolkit";

export const mapSingleSlice = createSlice({
  name: "mapsingle",
  initialState: {
    dataSearch: [],
  },
  reducers: {
    handleDataSearch(state, action) {
      let arr = [];
      action.payload.results.map((item) => {
        arr.push({
          label: item.name,
          info: item,
          value: `${item.name}`,
        });
      });
      state.dataSearch = arr;
    },
  },
});

export const { handleDataSearch } = mapSingleSlice.actions;
export const selectDataSearchMapSingle = (state) => state.mapsingle.dataSearch;
export default mapSingleSlice.reducer;
