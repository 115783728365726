import { call, put, takeEvery } from "redux-saga/effects";
import MapSingleService from "@redux/services/map-single-service";
import TYPE_ACTION from "@constants/action";
import { handleDataSearch } from "@redux/slice/map-single-slice";
//-----Worker
function* doGetSearchCameraSingle(action) {
  try {
    const {body,callback} = action.payload;
    const response = yield call(MapSingleService.getDataCameraSearchMap, body);
    const { data } = response;
    callback(data)
    yield put(handleDataSearch(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
//----Watcher
function* watchSearchCameraMapSingle() {
  yield takeEvery(
    TYPE_ACTION.MAPSINGLE.SEARCH_CAMERA_MAP_SINGLE,
    doGetSearchCameraSingle
  );
}
export {
    watchSearchCameraMapSingle
};
