const colors = {
	background: '#26292D',
	textBase: '#B4B4B4',
	text_active:"white",
	plahacoder: 'rgba(255, 255, 255, 0.4)',
	background_event: '#212228',
	backgroundSide: '#323539',
	text_color_dark: '#B5122E',
	background_header: '#767676',
	borderColor: '#424751',
	border_color_side: 'rgba(204, 204, 204, 0.2)',
	background_tag: '#101016',
	redGlobal: '#B5122E',
	bg_chart: '#2B2F33',
	bg_input:"#2C3034",
	bg_disable:"#8D9DB1",
	bg_color:"rgb(33, 34, 40)",
	background_active:"rgba(151, 71, 255, 1)",
    background_disable:"rgba(92, 104, 127, 1)",
	background_form:"rgba(70, 72, 82, 1)",
	bgShape:"rgba(119, 119, 255, 1)",
	colorLoading:"#1CD1A1"
};
export default colors;

export const sizes = {
	heightInput: 40,
	sizeButton:"100px"
  };