import { ConfigProvider, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { selectGroup } from "@redux/slice/live-slice";

const CustomTreeServer = ({
  initTreeData,
  onSelect,
  data = [],
  titleRender,
  showIcon = true,
}) => {

  const dispatch = useDispatch();
  const dataGroup = useSelector(selectGroup)
  const camRef = useRef({
    id: null,
    name: null,
    description: null,
    parentGroupId: null,
    childGroupIds: null,
    recorderIds: null,
    cameraIds: null,
  });

  const treeData = []

  
  useEffect(() => {
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIVE_GROUP,
      payload: { body: camRef.current },
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "rgba(16, 16, 22, 0)",
          controlItemBgActive: colors.transparent,
          lineHeight: 2,
          colorText: colors.textBase,
          colorPrimary: colors.redGlobal,
          colorBorder: colors.borderColor,
          paddingXS: 5,
        },
      }}
    >
      <Tree
        checkable ={false}
        treeData={dataGroup}
        showIcon={showIcon}
        height={233}
        switcherIcon={
          <DownOutlined size={24} style={{ height: "24px", width: "24px" }} />
        }
        showLine={true}
        onSelect={onSelect}
        titleRender={titleRender}
      />
    </ConfigProvider>
  );
};
export default CustomTreeServer;
