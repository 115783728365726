import CustomButtonCommon from "@common/button/custom-button";
import CustomInputNumber from "@common/input/input-number";
import colors from "@constants/colors";
import { handleChangeLayout } from "@redux/slice/live-slice";
import { Form } from "antd";
import { useRef } from "react";
import { useDispatch } from "react-redux";

const SetLayout = ({handleCancel}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const onChange = (value) => {
    // console.log('changed', value);
  }

    const onFinish = () => {
      formRef.current.validateFields().then((values) => {
        form.validateFields().then(async (values) => {
          const obj = { ...values };
          let item = {
            column: obj.column?obj.column:1,
            row: obj.row?obj.row:1,
          };
          dispatch(handleChangeLayout({column:item.column, row:item.row}))
          form.resetFields();
        });
      });
      };
    return ( 
      <Form form={form} ref={formRef}>
        <div className="justify-between px-3 pt-3 border">
        <CustomInputNumber name={"column"} title={"Số Cột"} width="100px" max={10} min={1} onChange={onChange} defaultValue={1} />
        <CustomInputNumber name={"row"} title={"Số Hàng"} width="100px" max={10} min={1} onChange={onChange} defaultValue={1} />
        <div className="flex justify-end pb-2 pr-2">
          <div className="pr-2">
            <CustomButtonCommon
              text={"Lưu"}
              width="100px"
              backgroundColor={colors.redGlobal}
              htmlType={"submit"}
              onClick={() => {
                onFinish()
                handleCancel();
              }}
            />
          </div>
          <CustomButtonCommon
            text={"Hủy"}
            width="100px"
            onClick={(e) => {
              handleCancel();
              form.resetFields();
            }}
          />
        </div>
        </div>
      </Form>
     );
}
 
export default SetLayout;